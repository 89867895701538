export const verbose = true;
export const appTitle = "Fusion Portal";
let apiUrl = location.origin + "/api/";
if (location.port) {
	apiUrl = "https://localhost:44337/api/";
}
//Local Storage keys
export const storageBaseKey = "fusion";
export const storageMultiRetailer = storageBaseKey + ".multiRetailer";
export const storageUserKey = storageBaseKey + ".user";
export const storagePolicyKey = storageBaseKey + ".policy";
export const storageRetailerIdKey = storageBaseKey + ".retailerid";
export const storageSelectedStore = storageBaseKey + ".store";
export const storageAppIdKey = storageBaseKey + ".appid";
export const storageIpKey = storageBaseKey + ".ip";
export const storageSearchOptionsKey = storageBaseKey + ".SearchOptions";
export const storageSignupAddressKey = storageBaseKey + ".Signup";

export const cartDb = storageBaseKey + ".Cart";
export const cacheDb = storageBaseKey + ".Cache";

//System message defaults
export const defaultMessagePosition = "top";
export const defaultMessageTimeout = 6000;

//Service Endpoints
export const urlAccountService = apiUrl + "account/";
export const urlBenService = apiUrl + "ben/";
export const urlCartService = apiUrl + "cart/";
export const urlCBCService = apiUrl + "cbc/";
export const urlContractorService = apiUrl + "contractor/";
export const urlContractorAdminService = apiUrl + "contractoradmin/";
export const urlDocumentService = apiUrl + "document/";
export const urlPaintColorService = apiUrl + "paintcolor/";
export const urlPaymentService = apiUrl + "payment/";
export const urlPayPalService = apiUrl + "paypal/";
export const urlPublicService = apiUrl + "public/";
export const urlUserService = apiUrl + "user/";

//CBC API
export const urlGetSummaryAmountsOwing = "payment/SummaryAmountsOwing/";

export const googlemap_Api_Key = "AIzaSyAeAWjD9urtAjVV67G2k9HgwD3co_NITek";
