//Site for testing regex: http://www.regular-expressions.info/javascriptexample.html
export const currencyValidation = (value) => {
	if (!value) return false;
	//const regex = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
	const regex = /^\d+(?:\.\d{0,2})$/;
	return regex.test(value.toString());
};

export const alphaAndSpace = (value) => {
	if (!value) return false;
	const regex = /^[a-zA-Z\s]*$/;
	return regex.test(value.toString());
};

export const alphaNumAndSpace = (value) => {
	if (!value) return false;
	const regex = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;
	return regex.test(value.toString());
};

//https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
export const password = (value) => {
	if (!value) return false;
	const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
	return regex.test(value.toString());
};
