export default [
  {
    path: "/contractorinvoices",
    name: "contractorinvoices",
    component: () => import(/* webpackChunkName: "contractor" */ "@/views/contractor/vInvoices.vue"),
    meta: { title: "Invoices", auth: true, roles: ["contractor"] },
  },
  {
    path: "/contractorstatements",
    name: "contractorstatements",
    component: () => import(/* webpackChunkName: "contractor" */ "@/views/contractor/vStatements.vue"),
    meta: { title: "Statements", auth: true, roles: ["contractor"] },
  },
  {
    path: "/contractorsearch",
    name: "contractorsearch",
    component: () => import(/* webpackChunkName: "contractor" */ "@/views/contractor/vSearch.vue"),
    meta: { title: "Search", auth: true, roles: ["contractor"] },
  },
  {
    path: "/contractorpayment",
    name: "contractorpayment",
    component: () => import(/* webpackChunkName: "contractor" */ "@/views/contractor/vPayment.vue"),
    meta: { title: "Payment", auth: true, roles: ["allowedPayments"] },
  },
  {
    path: "/contractorregulatory",
    name: "contractorregulatory",
    component: () => import(/* webpackChunkName: "contractor" */ "@/views/contractor/vRegulatory.vue"),
    meta: { title: "Regulatory", auth: true, roles: ["contractor"] },
  },
  {
    path: "/contractorusers",
    name: "contractorusers",
    component: () => import(/* webpackChunkName: "contractor" */ "@/views/contractor/vUsers.vue"),
    meta: { title: "Users", auth: true, roles: ["primary"] },
  },
];
