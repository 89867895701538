import vLogIn from "@/views/user/vLogIn.vue";
import vLogOut from "@/views/user/vLogOut.vue";

export default [
	{
		path: "/login/:retailerchanged?",
		name: "login",
		component: vLogIn,
		meta: { title: "Login", auth: false },
	},
	{
		path: "/signup",
		name: "signup",
		component: () => import(/* webpackChunkName: "user" */ "@/views/user/vSignUp.vue"),
		meta: { title: "Sign Up", auth: false },
	},
	{
		path: "/logout",
		name: "logout",
		component: vLogOut,
		meta: { title: "Logout", auth: false },
	},
	{
		path: "/profile",
		name: "profile",
		component: () => import(/* webpackChunkName: "user" */ "@/views/user/vProfile.vue"),
		meta: { title: "Profile", auth: true, roles: null },
	},
	{
		path: "/forgotpassword",
		name: "forgotpassword",
		component: () => import(/* webpackChunkName: "user" */ "@/views/user/vForgotPassword.vue"),
		meta: { title: "Password Recovery", auth: false, roles: null },
	},
	{
		path: "/resetpassword/:code",
		name: "resetpassword",
		component: () => import(/* webpackChunkName: "user" */ "@/views/user/vResetPassword.vue"),
		meta: { title: "Password Recovery", auth: false, roles: null },
	},
];
