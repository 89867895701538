<template>
	<div class="cart cart-icon" v-show="showCartIcon" v-b-toggle.cart-drawer>
		<font-awesome-icon icon="shopping-cart" />
		<div class="count" v-if="cartQuantity > 0">{{ cartQuantity }}</div>
	</div>
</template>

<script>
import * as _cartService from "@/services/cartService";
import { eventBus } from "@/main";

export default {
	name: "cAppCartIcon",
	components: {},
	props: [],

	data: () => ({ cartQuantity: 0 }),
	methods: {
		updateQuantity: function() {
			_cartService.getCart((response) => {
				if (response.Success) this.cartQuantity = response.Cart.length;
			});
		},
	},
	computed: {
		showCartIcon: function() {
			return this.$route.name != "checkout";
		},
	},
	watch: {},
	created() {
		//when wiring up event buses you need to dispose of any existing event listeners
		//with the "off" - otherwise you could end up with the event added more than once
		//and it would execute multipe times for each call
		eventBus.$off("cartQtyUpdated");
		eventBus.$on("cartQtyUpdated", (data) => {
			this.cartQuantity = data;
		});
	},
	mounted: function() {
		this.updateQuantity();
	},
};
</script>

<style></style>
