export default [
	{
		path: "/orderhistory",
		name: "orderhistory",
		component: () => import(/* webpackChunkName: "account" */ "@/views/account/vOrderHistory.vue"),
		meta: { title: "Order History", auth: true, roles: null },
	},
	{
		path: "/favorites",
		name: "favorites",
		component: () => import(/* webpackChunkName: "account" */ "@/views/account/vFavorites.vue"),
		meta: { title: "My Favorites", auth: true, roles: null },
	},
];
