<template>
	<div>
		<!-- 		<cHomeFilter v-model="options" v-bind:showWait="showOptionsWait" />
		<div class="accentColor2" v-b-toggle.drawer-product><font-awesome-icon icon="filter" /> Start Shopping</div>
 -->
		<cSplashMedia />
		<div class="row home-card">
			<template v-for="p in homePages">
				<cHomeCardElement v-bind:element="p" :editor="false" :key="p.HomePageCardId" />
			</template>
		</div>
	</div>
</template>

<script>
import cSplashMedia from "@/components/shared/cSplashMedia";
import cHomeFilter from "@/components/filters/cHomeFilter";
import cHomeCardElement from "@/components/shared/cHomeCardElement";

import * as _publicService from "@/services/publicService";
import * as _utility from "@/utility";
export default {
	name: "cHome",
	components: { cSplashMedia, cHomeFilter, cHomeCardElement },
	props: [],
	data: () => ({
		options: {
			Departments: [],
			Classes: [],
			Lines: [],
			Brands: [],
		},
		homePages: [],
		showOptionsWait: false,
	}),
	methods: {
		load() {
			_publicService.getHomeScreen((response) => {
				if (response.Success) {
					if (response.RetailerName && response.RetailerName.length > 0) this.$store.dispatch("saveStoreRetailerName", response.RetailerName).then((r) => {});
					this.homePages = response.HomePageCards;
				}
			});
			//let queryStringParameter = {
			//	Department: "",
			//	Class: "",
			//	Line: "",
			//	Brand: "",
			//	SearchTerm: "",
			//};
			//
			//_publicService.getProductSearchOptionsByQuerystring(queryStringParameter, (response) => {
			//	if (response.Success) {
			//		this.options.Departments = response.Departments;
			//		this.options.Classes = response.Classes;
			//		this.options.Lines = response.Lines;
			//		this.options.Brands = response.Brands;
			//	}
			//});
		},
	},
	computed: {},
	watch: {
		$route: {
			handler: function(route) {
				//console.log("cHome|watch|$route:", route.params);
				let paramRetailerId = -0;
				if (route.params != null && route.params.retailerId != null) {
					paramRetailerId = route.params.retailerId;
				}
				if (paramRetailerId == null || paramRetailerId == 0) {
					this.$router.push("/");
					return;
				}
				this.$store.dispatch("saveRetailerId", paramRetailerId).then((r) => this.load());
			},
			deep: true,
			immediate: true,
		},
	},
	mounted: function() {},
};
</script>

<style></style>
