<template>
	<div class="cinput-group">
		<label v-if="label && label.length > 0">{{ label }} <span v-if="isRequired" class="error">*</span></label>
		<div class="error" v-html="validationErrorMessage(value)"></div>
		<input :type="type" :placeholder="placeholder" :id="calculatedId" v-model="value.$model" :class="{ error: !fieldIsValid(value) }" trim @input="delayTouch(value)" />
	</div>
</template>

<script>
import * as _validation from "@/utility/validation";
const touchMap = new WeakMap();
export default {
	name: "cValidatedInput",
	components: {},
	props: {
		value: { default: null, type: Object },
		type: { default: "text", type: String },
		label: { default: "", type: String },
		placeholder: { default: "", type: String },
		elementId: { default: "", type: String },
		validationDelay: {
			default: 2000,
			type: Number,
		},
	},
	data: () => ({}),
	methods: {
		fieldIsValid(p) {
			return _validation.validationState(p);
		},
		validationErrorMessage(p) {
			return _validation.validationErrorMessage(p);
		},
		delayTouch($v) {
			$v.$reset();
			if (touchMap.has($v)) {
				clearTimeout(touchMap.get($v));
			}
			touchMap.set($v, setTimeout($v.$touch, this.validationDelay));
		},
		makeid(length) {
			let result = "";
			let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
			let charactersLength = characters.length;
			for (var i = 0; i < length; i++) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
			}
			return result;
		},
	},
	computed: {
		isRequired: function() {
			let p = this.value.$params;
			if (!this.value.$params.required) return false;
			return this.value.$params.required.type === "required";
			return false;
		},
		calculatedId: function() {
			if (this.elementId) return this.elementId;
			return this.makeid(8);
		},
	},
	watch: {},
	mounted: function() {},
};
</script>

<style></style>
