<template>
	<transition name="fade">
		<div v-if="loadComplete">
			<cSplashCarousel v-bind:pages="splashPages" />
			<!--<img v-if="videoSources.length === 0 && splashMedia.BannerImageUrl.length > 0" :src="splashMedia.BannerImageUrl" style="width:100%" />
			<video v-if="videoSources.length > 0" autoplay muted loop v-html="videoSources" :poster="splashMedia.BannerImageUrl" style="width:100%"></video>
			<br />-->
		</div>
	</transition>
</template>

<script>
import cSplashCarousel from "@/components/shared/cSplashCarousel";
import * as _publicService from "@/services/publicService";

export default {
	name: "cSplashMedia",
	components: { cSplashCarousel },
	props: [],
	data: () => ({
		loadComplete: false,
		//splashMedia: {
		//	BannerImageUrl: "/assets/images/video-placeholder.png",
		//	BannerVideoMp4Url: null,
		//	BannerVideoOggUrl: null,
		//	BannerVideoWebmUrl: null,
		//},
		splashPages: [],
	}),
	methods: {
		load() {
			_publicService.getSplashMedia((response) => {
				if (response.Success) {
					//this.splashMedia = response.SplashMedia;
					let cnt = 1;
					response.SplashPages.forEach((a) => {
						a.Pos = cnt;
						cnt++;
					});
					this.splashPages = response.SplashPages;
					this.loadComplete = true;
				}
			});
		},
	},
	computed: {
		retailerId() {
			return this.$store.getters.retailerId;
		},

		videoSources() {
			let src = "";
			if (this.splashMedia === null) return src;
			if (this.splashMedia.BannerVideoMp4Url)
				src += "<source src='" + this.splashMedia.BannerVideoMp4Url + "' type='video/mp4'>";
			if (this.splashMedia.BannerVideoOggUrl)
				src += "<source src='" + this.splashMedia.BannerVideoOggUrl + "' type='video/ogg'>";
			if (this.splashMedia.BannerVideoWebmUrl)
				src += "<source src='" + this.splashMedia.BannerVideoWebmUrl + "' type='video/webm'>";
			return src;
		},
	},
	watch: {
		retailerId(newValue) {
			this.load();
		},
	},
	mounted: function() {
		this.load();
	},
};
</script>
