import axios from "axios";
import store from "@/store";
import router from "@/router";
import * as _utility from "@/utility";
import * as _config from "@/config";

function getClientIp() {
	return new Promise((resolve) => {
		let clientIp = store.getters.clientIp;
		//console.log("coreService|getClientId|store.getter>", clientIp);
		if (clientIp === null || clientIp === "unknown") {
			//don't wait for the API to return, IP will be there for the next time...
			let url = "https://api.ipify.org/?format=json";
			axios
				.get(url)
				.then(function(htmlResponse) {
					let response = htmlResponse.data;
					store.dispatch("refreshClientIp", response.ip);
					//return resolve(response.ip);
				})
				.catch((error) => {
					//return resolve("unknown");
				});
			return resolve("1.1.1.1");
		} else return resolve(clientIp);
	});
}

function getCommonData(data) {
	return new Promise((resolve) => {
		getClientIp().then((clientIp) => {
			data.ClientIp = clientIp;
			//automatically append the token if it exists..
			let token = store.getters.token;
			if (token !== "") data.Token = token;
			data.AppId = store.getters.appId;
			data.RetailerId = store.getters.retailerId;
			data.RetailerStoreId = store.getters.retailerStoreId;
			//console.log("serviceCallGet | store.RetailerId :", data.RetailerId);
			let savedLocale = _utility.getFromStorage("locale");
			data.Locale = savedLocale ? savedLocale : "en";
			return resolve(data);
		});
	});
}

function serviceCallGet(url, data, showWait, callback) {
	getCommonData(data).then((data) => {
		//build the querystring
		let queryString = "";
		for (var key in data) {
			if (data.hasOwnProperty(key)) {
				queryString += key + "=" + data[key] + "&";
			}
		}
		if (queryString.length > 0) {
			url += "?" + queryString;
			if (queryString.substr(queryString.length - 1) == "&") queryString = queryString.slice(0, -1);
		}
		if (showWait) store.commit("showWait", true);
		axios
			.get(url)
			.then(function(htmlResponse) {
				let response = htmlResponse.data;
				if (!response.Success) {
					store.commit("dispatchMessage", {
						color: response.DisplayStyle === 3 ? "danger" : "warning",
						text: response.FailureInformation,
					});
				}
				if (response.TokenExpired) {
					store.dispatch("logOut").then((r) => {
						if (response.RetailerChanged) {
							router.push("/login/1");
						} else router.push("/login/");
					});
				}
				if (response.ForceLogIn && !store.getters.isAuthenticated()) {
					router.push("/login");
				}
				store.commit("portalAvailable", response.PortalAvailable);
				if (showWait) store.commit("showWait", false);
				if (callback) callback(response);
			})
			.catch((error) => {
				if (showWait) store.commit("showWait", false);
				//console.log("coreService|serviceCallGet|error:");
				// Error
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					//console.log("error.response:");
					//console.log(error.response.data);
					//console.log(error.response.status);
					//console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					//console.log("error.request:");
					//console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					//console.log("error.other", error.message);
				}
				if (callback)
					callback({
						Success: false,
						FailureInformation: "There was a problem calling the service",
					});
			});
	});
}

function serviceCallPost(url, data, showWait, callback) {
	getCommonData(data).then((data) => {
		//If we use .Net WebAPI we need to put data in a form,
		//but if we use CODE Framework WebAPI we need to send
		//data as a JSON payload..
		//let formData = createFormFromData(data);
		if (showWait) store.commit("showWait", true);
		axios
			.post(url, data)
			.then(function(htmlResponse) {
				let response = htmlResponse.data;
				if (!response.Success) {
					store.commit("dispatchMessage", {
						color: response.DisplayStyle === 3 ? "danger" : "warning",
						text: response.FailureInformation,
					});
				}
				if (response.TokenExpired) {
					store.dispatch("logOut").then((r) => {
						if (response.RetailerChanged) {
							router.push("/login/1");
						} else router.push("/login/");
					});
				}
				store.commit("portalAvailable", response.PortalAvailable);
				if (showWait) store.commit("showWait", false);
				if (callback) callback(response);
			})
			.catch((error) => {
				if (showWait) store.commit("showWait", false);
				//console.log("coreService|serviceCallPost|error:");
				// Error
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					//console.log("error.response:");
					//console.log(error.response.data);
					//console.log(error.response.status);
					//console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					//console.log("error.request:");
					//console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					//console.log("error.other", error.message);
				}
				if (callback)
					callback({
						Success: false,
						FailureInformation: "There was a problem calling the service",
					});
			});
	});
}

//Nested form data requires recursive processing...
//this was found at https://gist.github.com/ghinda/8442a57f22099bdb2e34
//and works for at least one level of nesting...
function createFormFromData(obj, form, namespace) {
	let fd = form || new FormData();
	let formKey;

	for (let property in obj) {
		if (obj.hasOwnProperty(property) && obj[property]) {
			if (namespace) {
				formKey = namespace + "[" + property + "]";
			} else {
				formKey = property;
			}

			// if the property is an object, but not a File, use recursively.
			if (obj[property] instanceof Date) {
				fd.append(formKey, obj[property].toISOString());
			} else if (typeof obj[property] === "object" && !(obj[property] instanceof File)) {
				createFormFromData(obj[property], fd, formKey);
			} else {
				// if it's a string or a File object
				fd.append(formKey, obj[property]);
			}
		}
	}

	return fd;
}
export { serviceCallGet, serviceCallPost, getClientIp };
