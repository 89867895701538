<template>
  <b-sidebar id="drawer-product" :title="$t('Filter')" shadow class="app-filter">
    <div v-if="showWait" class="wait-indicator">
      <img src="/assets/shopping/images/wait-spinner.gif" width="50" height="50" />
    </div>
    <!--department -->
    <div class="box">
      <cHomeFilterBoxContainer
        :title="$t('Department')"
        v-model="value.Departments"
        v-on:submit="submit"
      />
    </div>
    <!--classes -->
    <div class="box">
      <cHomeFilterBoxContainer :title="$t('Class')" v-model="value.Classes" v-on:submit="submit" />
    </div>
    <!--line -->
    <div class="box">
      <cHomeFilterBoxContainer :title="$t('Line')" v-model="value.Lines" v-on:submit="submit" />
    </div>
    <!--brands -->
    <div class="box">
      <cHomeFilterBoxContainer :title="$t('Brand')" v-model="value.Brands" v-on:submit="submit" />
    </div>
  </b-sidebar>
</template>

<script>
import cHomeFilterBoxContainer from "@/components/filters/cHomeFilterBoxContainer";

export default {
  name: "cHomeFilter",
  components: { cHomeFilterBoxContainer },
  props: ["value", "showWait"],
  data: () => ({}),
  methods: {
    submit() {
      this.$root.$emit("bv::toggle::collapse", "drawer-product");
      this.$emit("submit");
      //on small screens automatically close filter on apply
      //if (window.screen.availWidth < 567) this.$store.commit("showFilter", false);
    },
    toggleFilter() {
      this.$root.$emit("bv::toggle", "drawer-product");
    },
  },
  computed: {
    showFilter: function() {
      if (this.$route.path.indexOf("login") > -1) return false;
      return true;
    },
  },
  watch: {},
  mounted: function() {},
};
</script>

<style></style>
