<template>
	<div class="app-footer">
		© {{ $t("AddRightsReserved") }}. v{{ $version }}.<span class="small">{{ $store.getters.retailerId }}</span
		>&nbsp;
		<router-link to="/privacypolicy">{{ $t("Privacy Policy") }}</router-link>
	</div>
</template>

<script>
export default {
	name: "cAppFooter",
	components: {},
	props: [],
	data: () => ({}),
	methods: {},
	computed: {},
	watch: {},
	mounted: function() {},
};
</script>

<style></style>
