import mapper from "@/utility/mapper";
import constants from "@/utility/constants";
import * as _core from "@/services/coreService";
import * as _config from "@/config";

export const emptyGuid = "00000000-0000-0000-0000-000000000000";

export function newGuid() {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
		var r = (Math.random() * 16) | 0,
			v = c === "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export function b64toBlob(b64Data, contentType, sliceSize) {
	contentType = contentType || "";
	sliceSize = sliceSize || 512;
	var byteCharacters = atob(b64Data);
	var byteArrays = [];
	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);
		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		var byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	var blob = new Blob(byteArrays, { type: contentType });
	return blob;
}

export function scrollToTop() {
	let e = document.getElementsByClassName("app-content");
	if (e.length > 0) e[0].scrollTo(0, 0, "smooth");
}

export function saveToStorage(key, value) {
	localStorage.setItem(_config.storageBaseKey + "." + key, JSON.stringify(value));
}
export function getFromStorage(key) {
	let value = localStorage.getItem(_config.storageBaseKey + "." + key);
	if (!value) return null;
	return JSON.parse(value);
}

export function saveToSession(key, value) {
	sessionStorage.setItem(_config.storageBaseKey + "." + key, JSON.stringify(value));
}
export function getFromSession(key) {
	let value = sessionStorage.getItem(_config.storageBaseKey + "." + key);
	if (!value) return null;
	return JSON.parse(value);
}
export function delteFromSession(key) {
	sessionStorage.removeItem(key);
}

//--------------------------------------------------------------------------
// Color conversion helpers... only used on test page..
//--------------------------------------------------------------------------
//https://github.com/antimatter15/rgb-lab/blob/master/color.js
//http://colormine.org/convert/rgb-to-lab
//https://www.cs.rit.edu/~ncs/color/t_convert.html
export function cie2rgb(lab) {
	console.log(lab);
	var y = (lab[0] + 16) / 116,
		x = lab[1] / 500 + y,
		z = y - lab[2] / 200,
		r,
		g,
		b;

	x = 0.95047 * (x * x * x > 0.008856 ? x * x * x : (x - 16 / 116) / 7.787);
	y = 1.0 * (y * y * y > 0.008856 ? y * y * y : (y - 16 / 116) / 7.787);
	z = 1.08883 * (z * z * z > 0.008856 ? z * z * z : (z - 16 / 116) / 7.787);

	console.log("x: " + x + " y: " + y + " z: " + z);
	r = x * 3.2406 + y * -1.5372 + z * -0.4986;
	///r = x * 3.24 + y * -1.53 + z * -0.5;
	g = x * -0.9689 + y * 1.8758 + z * 0.0415;
	///g = x * -0.96 + y * 1.9 + z * 0.08;
	b = x * 0.0557 + y * -0.204 + z * 1.057;
	///b = x * 0.07 + y * -0.2 + z * 1.065;

	r = r > 0.0031308 ? 1.055 * Math.pow(r, 1 / 2.4) - 0.055 : 12.92 * r;
	g = g > 0.0031308 ? 1.055 * Math.pow(g, 1 / 2.4) - 0.055 : 12.92 * g;
	b = b > 0.0031308 ? 1.055 * Math.pow(b, 1 / 2.4) - 0.055 : 12.92 * b;

	return [parseInt(Math.max(0, Math.min(1, r)) * 255), parseInt(Math.max(0, Math.min(1, g)) * 255), parseInt(Math.max(0, Math.min(1, b)) * 255)];
}

//const REF_X = 95.047; // Observer= 2°, Illuminant= D65
//const REF_Y = 100.0;
//const REF_Z = 108.883;
const REF_X = 96.7; // Observer= 2°, Illuminant= D65
const REF_Y = 102.9;
const REF_Z = 111.8;
export function lab2xyz(l, a, b) {
	let y = (l + 16) / 116;
	let x = a / 500 + y;
	let z = y - b / 200;

	if (Math.pow(y, 3) > 0.008856) {
		y = Math.pow(y, 3);
	} else {
		y = (y - 16 / 116) / 7.787;
	}
	if (Math.pow(x, 3) > 0.008856) {
		x = Math.pow(x, 3);
	} else {
		x = (x - 16 / 116) / 7.787;
	}
	if (Math.pow(z, 3) > 0.008856) {
		z = Math.pow(z, 3);
	} else {
		z = (z - 16 / 116) / 7.787;
	}

	let xyz = {};
	xyz.X = x * REF_X;
	xyz.Y = y * REF_Y;
	xyz.Z = z * REF_Z;
	return xyz;
}

export function xyz2rgb(X, Y, Z) {
	//X from 0 to  95.047      (Observer = 2°, Illuminant = D65)
	//Y from 0 to 100.000
	//Z from 0 to 108.883
	//X = ofClamp(X, 0, 95.047);
	if (X < 0) X = 0;
	if (X > 95.047) X = 95.047;

	let x = X * 0.01;
	let y = Y * 0.01;
	let z = Z * 0.01;

	let r = x * 3.2406 + y * -1.5372 + z * -0.4986;
	let g = x * -0.9689 + y * 1.8758 + z * 0.0415;
	let b = x * 0.0557 + y * -0.204 + z * 1.057;

	if (r > 0.0031308) {
		r = 1.055 * Math.pow(r, 1 / 2.4) - 0.055;
	} else {
		r = 12.92 * r;
	}
	if (g > 0.0031308) {
		g = 1.055 * Math.pow(g, 1 / 2.4) - 0.055;
	} else {
		g = 12.92 * g;
	}
	if (b > 0.0031308) {
		b = 1.055 * Math.pow(b, 1 / 2.4) - 0.055;
	} else {
		b = 12.92 * b;
	}

	let rgb = {};
	rgb.R = Math.round(r * 255);
	rgb.G = Math.round(g * 255);
	rgb.B = Math.round(b * 255);
	return rgb;
}
