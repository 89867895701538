<template>
	<div>
		<div class="box-title">
			<div @click="isCollapsed = !isCollapsed">
				<span v-if="isCollapsed" class=" pull-right"><font-awesome-icon icon="angle-down"/></span>
				<span v-if="!isCollapsed" class=" pull-right"><font-awesome-icon icon="angle-up"/></span>
			</div>
			<label>{{ title }}</label>
		</div>
		<div>
			<div class="product-filter home" v-for="i in value" :key="i.Id">
				<a @click="navigateTo(i)">{{ i.Display }}</a>
				<!-- <router-link :to="getLink(i)">{{ i.Display }}</router-link> -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "cHomeFilterBoxContainer",
	components: {},
	props: ["title", "value", "initialiaze"],
	data: () => ({
		isCollapsed: false,
		showMore: false,
	}),
	methods: {
		clear() {
			this.value.forEach((i) => {
				i.Selected = false;
			});
		},
		getLink(i) {
			let url = "/products?" + this.title.toLowerCase() + "=" + i.Display;
			return url;
		},
		navigateTo(i) {
			let param = this.title == "Department" ? "dept" : this.title.toLowerCase();
			//let url = "/products?" + param + "=" + i.Display;
			let url = "/products?" + param + "=" + i.Id;
			this.$router.push(url);
			this.$emit("submit");
		},
		submit() {
			this.showMore = false;
			this.$emit("submit");
		},
	},
	computed: {},
	watch: {
		initialiaze: function(newValue) {
			this.isCollapsed = newValue === "collapsed";
		},
	},
	mounted: function() {
		this.isCollapsed = this.initialiaze === "collapsed";
	},
};
</script>

<style>
.box-title {
	line-height: initial;
	margin-bottom: 5px;
	border-bottom: 1px solid var(--baseColorLight);
}
.more-less {
	text-align: right;
	font-size: 0.8em;
	color: var(--accentColor2);
}
.more-less .svg-inline--fa {
	font-size: 0.8em;
}
.show-less {
	max-height: 200px;
	overflow: hidden;
}
.full-page {
	position: fixed;
	left: 0;
	right: 0;
	background-color: var(--accentBackgroundColor);
	bottom: 25px;
	top: 0;
	padding: 10px;
	z-index: 90;
	overflow-y: auto;
}
@media screen and (max-width: 576px) {
}
.full-page .product-filter {
	width: 200px;
	display: inline-block;
	margin-right: 10px;
}
</style>
