<template>
	<div class="panel panel-default">
		<div class="panel-heading">
			<h4 class="panel-title" @click="isCollapsed = !isCollapsed">
				<a>
					<span v-if="isCollapsed" class="badge pull-right"><font-awesome-icon icon="plus"/></span>
					<span v-if="!isCollapsed" class="badge pull-right"><font-awesome-icon icon="minus"/></span>
					{{ $t("Retailers") }}
				</a>
			</h4>
		</div>
		<div class="panel-collapse" :class="{ collapse: isCollapsed }">
			<div class="panel-body">
				<cRetailerSelect v-on:retailerSelected="ok()" ref="refRetailerSelect" />
			</div>
		</div>
	</div>
</template>

<script>
import cRetailerSelect from "@/components/public/cRetailerSelect";
import * as _publicService from "@/services/publicService";
import * as _userService from "@/services/userService";
import * as _utility from "@/utility";
export default {
	name: "cAccordianRetailers",
	components: { cRetailerSelect },
	props: ["initialiaze"],
	data: () => ({
		isCollapsed: false,
	}),
	methods: {
		ok() {
			//need to reload the home page...
			this.isCollapsed = "collapsed";
			this.$router.push({ name: "retailer", params: { retailerId: this.$store.getters.retailerId } });
		},
	},
	computed: {},
	watch: {
		initialiaze: function(newValue) {
			this.isCollapsed = newValue === "collapsed";
		},
		isCollapsed: function(newValue) {
			//console.info("cAccordianRetailers|isCollapsed", newValue);
			if (newValue === false && this.$refs.refRetailerSelect) this.$refs.refRetailerSelect.load();
		},
	},
	mounted: function() {
		this.isCollapsed = this.initialiaze === "collapsed";
	},
};
</script>

<style>
.panel-body .retailer-select {
	max-width: 600px;
}
.panel-body .retailer-logo {
	width: 50px;
}
</style>
