<template>
	<b-modal id="modal-store-picker" scrollable size="xl" class="scroll">
		<template #modal-header="{ close }">
			<div class="store-finder input">
				<input type="text" id="postal-input" v-model="zip" v-on:keyup.enter="searchPostal" :placeholder="$t('Postal Code')" />
				<button class="btn" @click="searchPostal">{{ $t("Search") }}</button>
				<button class="btn ml-2" @click="searchCurrentLocation" :title="$t('Use Current Location')">
					<font-awesome-icon icon="location-arrow" />
				</button>
				<div class="message">{{ message }}</div>
				<div v-show="showWarning">
					<div class="alert alert-warning">{{ $t("ChangeStoreEmptyCart") }}</div>
				</div>
			</div>

			<font-awesome-icon icon="times" @click="close()" class="pull-right" />
		</template>
		<div class="store-finder">
			<div class="store-container scroll" :style="{ height: containerHeight }">
				<div v-if="showWait" class="wait-background">
					<div class="wait-indicator">
						<!-- <img src="/assets/shopping/images/wait-spinner.gif" width="50" height="50" /> -->
						<b-spinner></b-spinner>
						<br />{{ $t("Please wait") }}...
					</div>
				</div>
				<div v-for="s in stores" :key="s.ps_id" @click="selectStore(s)" class="store-div" :class="{ selected: selectedStoreIsMyStore(s.Ps_id) }">
					<div class="distance">
						{{ s.DistanceInMiles | decimal }} mi<br />
						<button class="btn" v-if="!selectedStoreIsMyStore(s.Ps_id)" @click="makeMine(s)">{{ $t("Select") }}</button>
					</div>
					<div class="details">
						<div class="title">
							{{ s.Label }}. {{ s.Name }}
							<font-awesome-icon v-show="selectedStoreIsMyStore(s.Ps_id)" icon="check" />
						</div>
						<div>{{ s.Address }}</div>
						<div>{{ s.City }}, {{ s.State }} {{ s.Zip }}</div>
						<div>
							<a :href="'tel:' + s.Phone">{{ s.Phone }}</a>
						</div>
						<div v-html="s.Hours"></div>
					</div>
				</div>
				<div v-if="stores.length == 0">
					<div class="alert alert-info">{{ $t("NoNearbyStoresFound") }}</div>
				</div>
			</div>
			<div id="map" :style="{ height: containerHeight }">{{ $t("StoreFinderEnterPostalCode") }}</div>
		</div>
		<template #modal-footer>
			<button class="btn" @click="close()">{{ $t("Done") }}</button>
		</template>
	</b-modal>
</template>

<script>
import gmapsInit from "@/utility/googleMaps";
import * as _publicService from "@/services/publicService";
import * as _cartService from "@/services/cartService";

import * as _utility from "@/utility";
import { eventBus } from "@/main";

const options = {
	enableHighAccuracy: true,
	timeout: 5000,
	maximumAge: 0,
};
let map = null;

export default {
	name: "cStorePicker",
	components: {},
	props: { show: { type: Boolean, default: false } },
	data: () => ({
		selectedStore: null,
		zip: null,
		message: null,
		//default to center of US
		searchCenterLat: 41.851427,
		searchCenterLng: -99.08997,
		canGeoLocate: false,
		zoomLevel: 3,
		stores: [],
		searchPoint: "",
		showWait: false,
		showWarning: false,
	}),
	methods: {
		close() {
			this.$bvModal.hide("modal-store-picker");
		},
		load() {
			//const myStore = this.$store.getters.myStore;
			this.searchCurrentLocation();
			_cartService.getCart((response) => {
				if (response.Success) this.showWarning = response.Cart.length > 0;
			});
		},
		async loadMap(callback) {
			//https://developers.google.com/maps/documentation/javascript/adding-a-google-map
			//http://maps.google.com/mapfiles/ms/icons/orange-dot.png
			try {
				//Zoom Levels approximate detail:
				//1:  World
				//5:  Continent
				//10: City
				//15: Street
				//20: Building
				//zoomControl: boolean,
				//mapTypeControl: boolean,
				//scaleControl: boolean,
				//streetViewControl: boolean,
				//rotateControl: boolean,
				//fullscreenControl: boolean
				const mapOptions = { zoom: this.zoomLevel, fullscreenControl: false };
				const google = await gmapsInit();
				let el = document.getElementById("map");
				if (el) {
					map = new google.maps.Map(document.getElementById("map"), mapOptions);
					map.setCenter(this.searchCenterPos);

					const that = this;
					this.stores.forEach((s) => {
						let marker = new google.maps.Marker({
							position: { lat: s.Lat, lng: s.Lng },
							map: map,
							label: s.Label,
							//icon: "http://maps.google.com/mapfiles/ms/icons/blue.png",
						});
						marker.addListener("click", function () {
							that.selectLabel(marker.label);
						});
					});
				}

				if (callback) callback(true);
			} catch (error) {
				console.error(error);
				if (callback) callback(false);
			}
		},
		setDefaultStore() {
			const myStore = this.$store.getters.myStore;
			//console.info("setDefaultStore|current Store:", myStore);
			if (myStore && myStore.Ps_id === null) {
				let s = this.stores[0];
				//console.info("selecting store:", s);
				this.makeMine(s, false);
				this.selectStore(s);
			}
		},
		makeMine(store, closeModal = true) {
			this.$store.commit("saveStore", store);
			_publicService.saveStoresSelection(store.Ps_id, (response) => {});
			if (closeModal === true) this.$bvModal.hide("modal-store-picker");
			eventBus.$emit("storeSelected", store.Ps_id);
			eventBus.$emit("storeChanged");
		},
		searchCurrentLocation() {
			// Try HTML5 geolocation.
			const that = this;
			if (navigator.geolocation) {
				navigator.permissions.query({ name: "geolocation" }).then((r) => {
					if (r.state === "granted" || r.state === "prompt") {
						navigator.geolocation.getCurrentPosition(
							function (position) {
								that.searchLatLng(position.coords.latitude, position.coords.longitude, null);
								that.loadMap();
							},
							function (error) {
								that.searchRetailer();
								that.$store.commit("dispatchMessage", {
									color: "warning",
									text: that.$t("StoreFinderNoPermission"),
								});
							},
							options
						);
					} else {
						//its been deined...
						that.searchRetailer();
						that.$store.commit("dispatchMessage", {
							color: "warning",
							text: that.$t("StoreFinderNoPermission"),
						});
					}
				});
				// navigator.permissions.query({ name: "geolocation" }).then((r) => {
				// 	if (r.state === "prompt") {
				// 		this.searchRetailer();
				// 	} else if (r.state === "denied") {
				// 		this.searchRetailer();
				// 		this.$store.commit("dispatchMessage", {
				// 			color: "warning",
				// 			text: this.$t("StoreFinderNoPermission"),
				// 		});
				// 	} else {
				// 		navigator.geolocation.getCurrentPosition(function(position) {
				// 			that.searchLatLng(position.coords.latitude, position.coords.longitude, null);
				// 			that.loadMap();
				// 		});
				// 	}
				// });
			} else {
				this.$store.commit("dispatchMessage", {
					color: "warning",
					text: this.$t("StoreFinderGeoNotSupported"),
				});
			}
		},
		searchLatLng(lat, lng, storeToHighlight) {
			this.searchPoint = "";
			this.showWait = true;
			_publicService.getStoresByLatLng(lat, lng, (response) => {
				if (response.Success) {
					this.searchPoint = `(Lat: ${lat.toFixed(2)}, Lng: ${lng.toFixed(2)})`;
					const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
					let labelIndex = 0;
					response.Stores.forEach((s) => {
						s.Hours = s.Hours.replace("\r\n", "<br>");
						s.Label = labels[labelIndex++ % labels.length];
					});
					this.stores = response.Stores;
					this.searchCenterLat = response.CenterLat;
					this.searchCenterLng = response.CenterLng;
					this.zoomLevel = 10;
					let myStore = this.$store.getters.myStore;
					//this.message = "We found " + this.stores.length + " stores for " + myStore.RetailerName;
					this.message = this.$t("StoreFinderClosestLocation") + " " + this.searchPoint;
					this.loadMap();
					if (storeToHighlight !== null) {
						let s = this.stores.find((s) => s.Ps_id === storeToHighlight.Ps_id);
						this.selectStore(s);
					} else this.setDefaultStore();
				}
				this.showWait = false;
			});
		},
		searchPostal() {
			this.searchPoint = "";
			this.showWait = true;
			_publicService.getStoresByPostal(this.zip, (response) => {
				if (response.Success) {
					this.searchPoint = this.zip;
					const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
					let labelIndex = 0;
					response.Stores.forEach((s) => {
						s.Hours = s.Hours.replace("\r\n", "<br>");
						s.Label = labels[labelIndex++ % labels.length];
					});
					this.stores = response.Stores;
					this.searchCenterLat = response.CenterLat;
					this.searchCenterLng = response.CenterLng;
					this.zoomLevel = 10;
					//this.message = "We found " + this.stores.length + " stores within 50 miles of " + this.zip;
					this.message = this.$t("StoreFinderClosestZip") + " " + this.$t("Postal Code") + " " + this.zip;
					this.loadMap();
					this.setDefaultStore();
				}
				this.showWait = false;
			});
		},
		searchRetailer() {
			this.showWait = true;
			_publicService.getStoresForRetailer((response) => {
				if (response.Success) {
					const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
					let labelIndex = 0;
					response.Stores.forEach((s) => {
						s.Hours = s.Hours.replace("\r\n", "<br>");
						s.Label = labels[labelIndex++ % labels.length];
					});
					this.stores = response.Stores;
					this.searchCenterLat = response.CenterLat;
					this.searchCenterLng = response.CenterLng;
					this.zoomLevel = 10;
					this.loadMap();
					this.setDefaultStore();
				}
				this.showWait = false;
			});
		},
		selectLabel(label) {
			let store = this.stores.find((s) => s.Label == label);
			if (store) this.selectedStore = store;
			map.setCenter({ lat: store.Lat, lng: store.Lng });
			map.setZoom(15);
		},
		selectStore(store) {
			this.selectedStore = store;
			map.setCenter({ lat: store.Lat, lng: store.Lng });
			map.setZoom(15);
		},
		selectedStoreIsMyStore(ps_id) {
			const myStore = this.$store.getters.myStore;
			return myStore.Ps_id === ps_id;
		},
	},
	computed: {
		containerHeight() {
			let c = document.getElementById("main-content");
			if (c) return c.offsetHeight - (this.showWarning ? 225 : 150) + "px";
			return "500px";
		},
		searchCenterPos() {
			if (this.searchCenterLat === null) return {};
			return {
				lat: this.searchCenterLat,
				lng: this.searchCenterLng,
			};
		},
	},
	created() {
		//when wiring up event buses you need to dispose of any existing event listeners
		//with the "off" - otherwise you could end up with the event added more than once
		//and it would execute multipe times for each call
		eventBus.$off("storePickerOpened");
		eventBus.$on("storePickerOpened", () => {
			this.load();
		});
	},
	watch: {},
	mounted: function () {
		this.load();
	},
};
</script>

<style>
.product.store-picker {
	text-align: left;
	background-color: rgba(0, 0, 0, 0.25);
	padding: 15px;
	margin: 0;
	position: fixed;
	top: calc(var(--topBarHeight) + var(--subBarHeight) + var(--storeBarHeight));
	left: 0;
	right: 0;
	bottom: var(--footerHeight);
	z-index: 1251;
}
.product .store-picker-content {
	position: absolute;
	top: 0;
	right: 10%;
	left: 10%;
	bottom: calc(var(--footerHeight) - 15px);
	background-color: var(--accentBackgroundColor);
}
.product.store-picker .btn.absolute {
	bottom: 57px;
	right: 30px;
}
.product.store-picker .store-div {
	background-color: white;
}
.product.store-picker .store-div .details {
	width: 60%;
}

@media screen and (max-width: 769px) {
	.product .store-picker-content {
		width: auto;
		right: -10px;
		left: -9px;
		top: -10px;
		bottom: -11px;
	}
	.product .store-container.scroll {
		height: calc(100vh - 200px);
		overflow-y: auto;
	}
}
</style>
