import i18n from "../i18n";
function t(term) {
	let l = i18n.messages[i18n.locale][term];
	return l ? l : term;
}
export function formIsValid(form) {
	return !form.$invalid;
}

export function validationState(property) {
	////console.log("utility/validation|validationState|property:");
	////console.log(property);
	let result = property.$dirty ? !property.$error : true;
	////console.log("returning: " + result);
	return result;
}

//https://vuelidate.js.org/#sub-builtin-validators
export function validationErrorMessage(property) {
	////console.log("utility/validation.js|validationErrorMessage:");
	////console.log(property);
	if (property.expDate != null && property.expDate == false) return t("ValidationInvalidExpiration");
	if (!property.$dirty && !property.$error) return "";
	if (property.currencyValidation != null && property.currencyValidation == false) return t("ValidationCurrency");
	if (property.alphaAndSpace != null && property.alphaAndSpace == false) return t("ValidationOnlyLetterSpace");
	if (property.alphaNumAndSpace != null && property.alphaNumAndSpace == false) return t("ValidationNoSpecialCharacters");
	if (property.password != null && property.password == false) return t("ValidationPassword");
	if (property.emailAvailable != null && property.emailAvailable == false) return t("ValidationEmailInUse");
	for (let p in property.$params) {
		let paramName = p;
		let paramValue = property.$params[p];
		for (var v in paramValue) {
			var paramValueName = v;
			var paramValueValue = paramValue[v];

			////console.log(paramName + ":" + paramValueName + ":" + paramValueValue);
			switch (paramName) {
				case "required":
					if (!property.required) return t("ValidationRequired");
					break;
				case "minLength":
					if (!property.minLength) return t("ValidationMinLength").replace("{0}", property.$params.minLength.min);
					break;
				case "maxLength":
					if (!property.maxLength) return t("ValidationMaxLength").replace("{0}", property.$params.maxLength.max);
					break;
				case "email":
					if (!property.email) return t("ValidationInvalidEmail");
					break;
				case "sameAs":
					if (!property.sameAs) return t("ValidationPasswordNoMatch");
					break;
				case "alpha":
					if (!property.alpha) return t("ValidationAlphaOnly");
					break;
				case "alphaNum":
					if (!property.alphaNum) return t("ValidationAlphaNumericOnly");
					break;
				//Add Additional validation messages here....
				default:
					return paramName + t("ValidationUnknown");
			}
		}
	}
	return "";
}
