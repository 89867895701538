<template>
	<div>
		<b-modal id="modal-retailer-select" scrollable size="lg" :title="$t('ChangeRetailerInstructions')">
			<cRetailerSelect v-on:retailerSelected="ok()" />
			<template #modal-footer>
				<div class="row">
					<div class="col-md-12">
						<p class="float-left accentColor2 small" v-show="!isAuthenticated">{{ $t("ChangeStoreEmptyCart") }}</p>
					</div>
					<div class="col-md-12">
						<!-- <button class="btn ml-3 float-right" @click="ok()">{{ $t("Ok") }}</button> -->
						<button class="btn float-right" @click="cancel()">{{ $t("Done") }}</button>
					</div>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import cRetailerSelect from "@/components/public/cRetailerSelect";

import * as _publicService from "@/services/publicService";
import * as _userService from "@/services/userService";
import * as _utility from "@/utility";
import { eventBus } from "@/main";

export default {
	name: "cRetailerSelectModal",
	components: { cRetailerSelect },
	props: [],
	data: () => ({
		load() {
			let multiRetailer = this.$store.getters.multiRetailer;
			if (!multiRetailer) return;
			_userService.getRetailers((response) => {
				if (response.Retailers.length > 1) {
					let prompted = _utility.getFromSession("retailerselect");
					if (prompted != 1) {
						_utility.saveToSession("retailerselect", 1);
						this.$bvModal.show("modal-retailer-select");
					}
				}
			});
		},
		cancel() {
			this.$bvModal.hide("modal-retailer-select");
		},
		ok() {
			//need to reload the home page...
			this.$bvModal.hide("modal-retailer-select");
			this.$router.push({ name: "retailer", params: { retailerId: this.$store.getters.retailerId } });
		},
	}),
	methods: {},
	computed: {
		isAuthenticated: function () {
			return this.$store.getters.isAuthenticated() == true;
		},
	},
	watch: {},
	mounted: function () {
		this.load();
	},
};
</script>

<style>
#modal-retailer-select h5 {
	color: var(--accentColor2);
}
</style>
