import store from "../store";
import * as _cacheIndexDb from "./cacheIndexDbService";
import * as _core from "./coreService";
import * as _config from "@/config";
import * as _device from "@/utility/device";

export function isCreateAccountAllowed(callback) {
	let url = _config.urlUserService + "IsCreateAccountAllowed/";
	let data = {};
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function createAccount(form, callback) {
	let url = _config.urlUserService + "CreateAccount/";
	let data = form;
	data.UserName = form.Email;
	data.DeviceInfo = _device.device.getOsAndBrowser();
	_core.serviceCallPost(url, data, true, (response) => {
		if (response.Success) {
			store
				.dispatch("logIn", {
					email: form.Email,
					token: response.Token,
					name: "",
					//retailerId: response.RetailerId,
					portalAvailable: response.PortalAvailable,
					rememberMe: form.RememberMe,
					info: response.Info,
				})
				.then((dispatchSuccess) => {
					if (callback) callback(response);
				});
		} else {
			if (callback) callback(response);
		}
	});
}

export function deleteUserFavorite(data, callback) {
	let url = _config.urlUserService + "DeleteUserFavorite/";
	_core.serviceCallPost(url, data, false, (response) => {
		if (callback) callback(response);
	});
}

export function forgotPassword(email, callback) {
	let url = _config.urlUserService + "ForgotPassword/";
	let data = { Email: email };
	_core.serviceCallPost(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getEmailForCode(code, callback) {
	let url = _config.urlUserService + "GetEmailForCode/";
	let data = { Code: code };
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getProfile(callback) {
	let url = _config.urlUserService + "GetProfile/";
	let data = {};
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getUserFavorites(callback) {
	let url = _config.urlUserService + "GetUserFavorites/";
	let data = {};
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getRetailers(callback) {
	_cacheIndexDb.getRetailers().then((response) => {
		callback(response);
	});
	//}
}

export function isEmailUsed(email, callback) {
	let url = _config.urlUserService + "IsEmailUsed/";
	let data = { Email: email };
	_core.serviceCallPost(url, data, false, (response) => {
		if (callback) callback(response);
	});
}

export function logIn(form, callback) {
	let url = _config.urlUserService + "LogIn/";
	let data = { UserName: form.Email, Password: form.Password, Retailers: [] };
	_cacheIndexDb
		.getRetailers()
		.then((response) => {
			if (response.Success) {
				response.Retailers.forEach((r) => {
					data.Retailers.push({ RetailerId: r.RetailerId, StoreId: r.StoreId ? r.StoreId : null });
				});
			}
		})
		.then(() => {
			data.DeviceInfo = _device.device.getOsAndBrowser();
			_core.serviceCallPost(url, data, true, (response) => {
				if (response.Success) {
					store
						.dispatch("logIn", {
							email: form.Email,
							token: response.Token,
							name: "",
							role: 99,
							//retailerId: response.RetailerId,
							portalAvailable: response.PortalAvailable,
							rememberMe: form.RememberMe,
							info: response.Info,
						})
						.then((dispatchSuccess) => {
							//Save any Retailer selections returned from DB
							response.Retailers.forEach((r) => {
								//retailerId, retailerName, base64EncodedString, retailerCountry, storeId, storeName;
								_cacheIndexDb.saveRetailerLogo(r.RetailerId, r.RetailerName, r.Base64EncodedString, r.Country, r.StoreId, r.StoreName).then((response) => {
									//just fire and forget...
								});
							});
							//If the user had previously saved a store it will be returned from the log in service
							if (response.Store.Rt_id > 0) store.commit("saveStore", response.Store);
							if (callback) callback(response);
						});
				} else {
					if (callback) callback(response);
				}
			});
		});
}

export function logOut(callback) {
	let url = _config.urlUserService + "LogOut/";
	let data = {};
	_core.serviceCallPost(url, data, true, (response) => {
		if (response.Success) {
			store.dispatch("logOut").then((dispatchSuccess) => {
				if (callback) callback(response);
			});
		} else {
			if (callback) callback(response);
		}
	});
}

export function resetPassword(form, callback) {
	let url = _config.urlUserService + "ResetPassword/";
	let data = { Email: form.Email, NewPassword: form.NewPassword, Code: form.Code };
	_core.serviceCallPost(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function removeRetailer(retailerId, callback) {
	if (store.getters.isAuthenticated()) {
		let url = _config.urlUserService + "DeleteUserRetailer/";
		let data = { RetailerIdToDelete: retailerId };
		_core.serviceCallPost(url, data, true, (response) => {
			if (callback) callback(response);
			_cacheIndexDb.removeRetailer(retailerId).then((response) => {
				//fire and forget
			});
		});
	} else {
		_cacheIndexDb.removeRetailer(retailerId).then((response) => {
			if (callback) callback(response);
		});
	}
}

export function saveUserFavorite(info, callback) {
	//info = { UserFavoriteId: _utility.empGuid, ProductId: null, UPC: null, ColorId: null, SheenId: null, UomId: null }
	let url = _config.urlUserService + "SaveUserFavorite/";
	let data = { FavoriteInfo: info };
	_core.serviceCallPost(url, data, false, (response) => {
		if (callback) callback(response);
	});
}

export function updatePassword(data, callback) {
	let url = _config.urlUserService + "UpdatePassword/";
	//let data = {};
	_core.serviceCallPost(url, data, true, (response) => {
		if (callback) callback(response);
	});
}
