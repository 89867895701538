import store from "../store";
import * as _core from "./coreService";
import * as _config from "@/config";
import * as _cacheIndexDb from "./cacheIndexDbService";
import * as _utility from "@/utility";

export function getAdBanners(callback) {
	let url = _config.urlPublicService + "GetAdBanners/";
	let data = { IsMobile: window.innerWidth < 567 };
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getComboProductDetail(productId, callback) {
	let url = _config.urlPublicService + "GetComboProductDetail/";
	let data = { ProductId: productId };
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getHelpDocument(id, name, callback) {
	let url = _config.urlPublicService + "GetHelpDocument/";
	let data = { Id: id, Name: name };
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getHomeScreen(callback) {
	let url = _config.urlPublicService + "GetHomeScreen/";
	let data = { LastUpdateDate: "", NumberOfPages: 0 };
	//first look in the cache db
	_cacheIndexDb.getHomePageCards(store.getters.retailerId).then((cacheResponse) => {
		if (cacheResponse.Success && cacheResponse.HomePageCards.length > 0) {
			//we found some in the cache, get the quantity and latest "LastUpdateDate" to send into the service
			data.LastUpdateDate = cacheResponse.HomePageCards.reduce((a, b) => (a.LastUpdateDate > b.LastUpdateDate ? a : b)).LastUpdateDate;
			data.NumberOfPages = cacheResponse.HomePageCards.length;

			//If the language was changed since last query we need to get new localized one
			//see what the last language sent to the server was...
			let lastLanguage = _utility.getFromStorage("locale.card");
			let currentLanguage = _utility.getFromStorage("locale");
			if (lastLanguage !== currentLanguage) data.LastUpdateDate = null;

			//console.log("publicService|getHomeScreen", data);
			_core.serviceCallGet(url, data, true, (response) => {
				if (response.Store.Rt_id > 0) store.commit("saveStore", response.Store);

				//we have newer SplashPages, update cache and send back new records
				if (response.Success && response.HomePageCards.length > 0) {
					if (lastLanguage !== currentLanguage) _utility.saveToStorage("locale.card", currentLanguage);
					_cacheIndexDb.saveHomePageCards(store.getters.retailerId, response.HomePageCards).then((response) => {
						//just fire and forget to save the latest to cache...
					});
					if (callback) callback(response);
				} else {
					//nothing newer was found, just use the cached records...
					if (callback) callback(cacheResponse);
				}
			});
		} else {
			//console.log("publicService|getHomeScreen");
			//console.log(data);
			//if for some reason the cache db failed just call the service
			_core.serviceCallGet(url, data, true, (response) => {
				if (response.Success && response.HomePageCards.length > 0) {
					_cacheIndexDb.saveHomePageCards(store.getters.retailerId, response.HomePageCards).then((response) => {
						//just fire and forget to save the latest to cache...
					});
					if (response.Store.Rt_id > 0) store.commit("saveStore", response.Store);
				}
				if (callback) callback(response);
			});
		}
	});
}

//getPrivacyPolicy
export function getPrivacyPolicy(callback) {
	let url = _config.urlPublicService + "GetPrivacyPolicy/";
	let data = {};
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

///Does NOT trigger App wait indicator
export function getProductSearchOptions(selectedOptions, callback) {
	let url = _config.urlPublicService + "GetProductSearchOptions/";
	let data = { SelectedOptions: selectedOptions };
	_core.serviceCallPost(url, data, false, (response) => {
		if (callback) callback(response);
	});
}

///Does NOT trigger App wait indicator
export function getProductSearchOptionsByQuerystring(queryStringParameter, callback) {
	let url = _config.urlPublicService + "GetProductSearchOptionsByQuerystring/";
	let data = { QueryString: queryStringParameter };
	_core.serviceCallPost(url, data, false, (response) => {
		if (callback) callback(response);
	});
}

export function getAllProductSearchOptions(queryStringParameter, callback) {
	let url = _config.urlPublicService + "GetProductSearchOptionsByQuerystring/";
	let data = { QueryString: queryStringParameter };
	let retailerId = store.getters.retailerId;
	let staleCache = true;
	let ls = localStorage.getItem(_config.storageSearchOptionsKey);
	if (ls != null) {
		let cache = JSON.parse(ls);
		let d = new Date(cache.Date);
		staleCache = retailerId != cache.RetailerId || d.getDay() != new Date().getDay();
	}
	//console.info("publicService|getAllProductSearchOptions|searchCache, stale:", ls, staleCache);
	//console.info("publicService|getAllProductSearchOptions|querystring:", queryStringParameter);

	//If the language was changed since last query we need to get new localized one
	//see what the last language sent to the server was...
	let lastLanguage = _utility.getFromStorage("locale.search");
	let currentLanguage = _utility.getFromStorage("locale");
	if (lastLanguage !== currentLanguage) staleCache = true;

	//If we haven't loaded the options today grab them from the service
	if (staleCache) {
		_core.serviceCallPost(url, data, false, (response) => {
			//console.info("publicService|getAllProductSearchOptions|staleCache calling service", response);
			_cacheIndexDb.saveAllProductSearchOptions(response).then((r) => {
				localStorage.setItem(_config.storageSearchOptionsKey, JSON.stringify({ RetailerId: retailerId, Date: new Date() }));
				if (lastLanguage !== currentLanguage) _utility.saveToStorage("locale.search", currentLanguage);
			});
			if (callback) callback(response);
		});
	} else {
		_cacheIndexDb.getAllProductSearchOptions(store.getters.retailerId).then((cacheResponse) => {
			//console.info("publicService|getAllProductSearchOptions cache:", cacheResponse);
			if (cacheResponse.Success && cacheResponse.Brands.length > 0) {
				if (callback) callback(cacheResponse);
			} else {
				_core.serviceCallPost(url, data, false, (response) => {
					//console.info("publicService|getAllProductSearchOptions calling service");
					_cacheIndexDb.saveAllProductSearchOptions(response).then((r) => {
						localStorage.setItem(_config.storageSearchOptionsKey, JSON.stringify({ RetailerId: retailerId, Date: new Date() }));
					});
					if (callback) callback(response);
				});
			}
		});
	}
}

export function getProducts(page, recordsPerPage, sortColumn, sortDirection, selectedOptions, searchTerm, showWait, callback) {
	let url = _config.urlPublicService + "GetProducts/";
	let data = {
		Page: page,
		RecordsPerPage: recordsPerPage,
		SortColumn: sortColumn,
		SortDirection: sortDirection,
		SelectedOptions: selectedOptions,
		SearchTerm: searchTerm,
	};
	_core.serviceCallPost(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getProductDetail(productId, callback) {
	let url = _config.urlPublicService + "GetProductDetail/";
	let data = { ProductId: productId };
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getProductQuantity(upc, callback) {
	let url = _config.urlPublicService + "GetProductQuantity/";
	let data = { UPC: upc };
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}
export function getQuantityForProducts(upcs, callback) {
	let url = _config.urlPublicService + "GetQuantityForProducts/";
	let data = { UPCS: upcs };
	//Note: This is a post to support the request object having an array
	_core.serviceCallPost(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getProductQuantitiesNearby(upc, callback) {
	let url = _config.urlPublicService + "GetProductQuantitiesNearby/";
	let data = { UPC: upc };
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getProductIdByCode(code, callback) {
	let url = _config.urlPublicService + "GetProductIdByCode/";
	let data = { Code: code };
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getProductImage(productId, callback) {
	let url = _config.urlPublicService + "GetProductImage/";
	let data = { ProductId: productId };
	_core.serviceCallGet(url, data, false, (response) => {
		if (callback) callback(response);
	});
}

export function getProductImageByUPC(upc, callback) {
	let url = _config.urlPublicService + "GetProductImageByUPC/";
	let data = { UPC: upc };
	_core.serviceCallGet(url, data, false, (response) => {
		if (callback) callback(response);
	});
}

export function getRetailerLogoCached(callback) {
	_cacheIndexDb.getLogo(store.getters.retailerId).then((response) => {
		callback(response);
	});
}

export function getRetailerLogo(callback) {
	_cacheIndexDb.getLogo(store.getters.retailerId).then((response) => {
		if (response.Base64EncodedString != null) {
			callback(response);
		} else {
			let url = _config.urlPublicService + "GetRetailerLogo/";
			let data = {};
			_core.serviceCallPost(url, data, true, (response) => {
				if (response.Success) {
					//retailerId, retailerName, base64EncodedString, retailerCountry, storeId, storeName
					_cacheIndexDb.saveRetailerLogo(store.getters.retailerId, response.Name, response.Base64EncodedString, response.Country).then((response) => {
						//just fire and forget...
					});
				}
				if (callback) callback(response);
			});
		}
	});
}

export function getSplashMedia(callback) {
	let url = _config.urlPublicService + "GetSplashMedia/";
	let data = { LastUpdateDate: "", NumberOfPages: 0 };
	//first look in the cache db
	_cacheIndexDb.getSplashPages(store.getters.retailerId).then((cacheResponse) => {
		if (cacheResponse.Success && cacheResponse.SplashPages.length > 0) {
			//we found some in the cache, get the quantity and latest "LastUpdateDate" to send into the service
			data.LastUpdateDate = cacheResponse.SplashPages.reduce((a, b) => (a.LastUpdated > b.LastUpdated ? a : b)).LastUpdated;
			data.NumberOfPages = cacheResponse.SplashPages.length;
			//console.log("publicService|getSplashMedia");
			//console.log(data);
			_core.serviceCallGet(url, data, true, (response) => {
				//we have newer SplashPages, update cache and send back new records
				if (response.Success && response.SplashPagesUpdated == true) {
					_cacheIndexDb.saveSplashPages(store.getters.retailerId, response.SplashPages).then((response) => {
						//just fire and forget to save the latest to cache...
					});
					if (callback) callback(response);
				} else {
					//nothing newer was found, just use the cached records...
					if (callback) callback(cacheResponse);
				}
			});
		} else {
			//if for some reason the cache db failed just call the service
			_core.serviceCallGet(url, data, true, (response) => {
				if (response.Success && response.SplashPagesUpdated == true) {
					_cacheIndexDb.saveSplashPages(store.getters.retailerId, response.SplashPages).then((response) => {
						//just fire and forget to save the latest to cache...
					});
				}
				if (callback) callback(response);
			});
		}
	});
}

export function getStoresByLatLng(lat, lng, callback) {
	let url = _config.urlPublicService + "GetStoresForLocation/";
	let data = { Lat: lat, Lng: lng };
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getStoresByPostal(zip, callback) {
	let url = _config.urlPublicService + "GetStoresForLocation/";
	let data = { Zip: zip };
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getStoresForRetailer(callback) {
	let url = _config.urlPublicService + "GetStoresForRetailer/";
	let data = {};
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function saveStoresSelection(storeId, callback) {
	let url = _config.urlPublicService + "SaveStoresSelection/";
	let data = { StoreId: storeId };
	_core.serviceCallPost(url, data, true, (response) => {
		_cacheIndexDb.saveRetailerStore(response.RetailerId, response.StoreId, response.StoreName).then((response) => {});
		if (callback) callback(response);
	});
}

export function getTintedPaintDetail(productId, callback) {
	let url = _config.urlPublicService + "GetTintedPaintDetail/";
	let data = { ProductId: productId };
	_core.serviceCallGet(url, data, true, (response) => {
		if (callback) callback(response);
	});
}

export function getTintedPaintColors(search, productId, showWait = true, callback) {
	let url = _config.urlPublicService + "GetTintedPaintColors/";
	let data = { SearchPhrase: search, ProductId: productId };
	_core.serviceCallGet(url, data, showWait, (response) => {
		if (callback) callback(response);
	});
}

export function getTintedPaintSheenDetail(sheenId, callback) {
	let url = _config.urlPublicService + "GetTintedPaintSheenDetail/";
	let data = { SheenId: sheenId };
	_core.serviceCallGet(url, data, false, (response) => {
		if (callback) callback(response);
	});
}
