<template>
	<div class="app-store-bar">
		<div @click="openStorePicker">
			{{ storeDisplay }}
			<font-awesome-icon icon="map-location-dot" />
		</div>
	</div>
</template>

<script>
import { eventBus } from "@/main";
import * as _publicService from "@/services/publicService";
export default {
	name: "cAppStoreBar",
	components: {},
	props: [],
	data: () => ({
		retailerId: 0,
		retailerName: "",
	}),
	methods: {
		load() {},

		openStorePicker() {
			this.$bvModal.show("modal-store-picker");
			setTimeout(() => {
				eventBus.$emit("storePickerOpened");
				let el = document.getElementById("postal-input");
				if (el) el.focus();
			}, 500);
		},
	},
	computed: {
		storeDisplay: function () {
			if (!this.$store.getters.storeName || !this.$store.getters.myStore) return "";
			let display = this.$store.getters.storeName + " " + this.$store.getters.myStore.Address;
			return window.screen.width > 576 ? display : display.substring(0, 45) + "..";
		},
	},
	watch: {
		"$store.state.common.myStore": {
			handler: function (newStore) {
				let s = this.$store.getters.myStore;
				//console.info("cAppSubBar|watch|myStore:", s.RetailerName, s.Name);
				if (s) {
					let result = s.RetailerName;
					//if (s.Name.length > 0) result += ", " + s.Name;
					result += ", " + s.Name;
					this.retailerName = result;
				}
			},
			deep: true,
			immediate: true,
		},
		"$store.state.common.retailerId": {
			handler: function (newvalue) {
				this.retailerId = newvalue;
				//console.info("cAppSubBar|watch|user:", newvalue);
				//if (retailerId != 0) this.retailerId = newStore.retailerId;
			},
			deep: true,
			immediate: true,
		},
	},
	mounted: function () {},
};
</script>

<style>
.app-store-bar {
	position: fixed;
	top: calc(var(--topBarHeight) + var(--subBarHeight));
	left: 0;
	right: 0;
	height: var(--storeBarHeight);
	line-height: 45px;
	text-align: center;
	visibility: hidden;
}

@media screen and (max-width: 576px) {
	.app-store-bar {
		visibility: visible;
	}
}
</style>
