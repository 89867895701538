<template>
	<!--.replace("{0}","<router-link to="/login">").replace("{1}","</router-link>")-->
	<div class="app-content" v-html="html"></div>
</template>

<script>
import * as _userService from "@/services/userService";
import { eventBus } from "@/main";

export default {
	name: "vLogOut",
	components: {},
	props: ["value"],
	data: () => ({
		userInfo: null,
	}),
	methods: {
		load() {
			//eventBus.$emit("emptyCart", false);
			_userService.logOut((response) => {});
		},
	},
	computed: {
		html: function() {
			return this.$t("LogoutSuccess")
				.replace("{0}", '<a href="/login">')
				.replace("{1}", "</a>");
		},
	},
	watch: {},
	mounted: function() {
		this.load();
	},
};
</script>

<style></style>
