import Vue from "vue";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { componentsPlugin } from "bootstrap-vue";
dayjs.extend(localizedFormat);

Vue.filter("toMMDDYY", function(value) {
	if (!value) return "";
	return dayjs(value).format("L");
});

Vue.filter("toMMDDYYHHmm", function(value) {
	if (!value) return "";
	return dayjs(value).format("L LT");
});

Vue.filter("toMMDDYYHHmmNoOffset", function(value) {
	if (!value) return "";
	let d = dayjs(value);
	return d.add(-d.utcOffset(), "m").format("L LT");
});

Vue.filter("toDayOfWeek", function(value) {
	if (!value) return "";
	return dayjs(value).format("dddd");
});

Vue.filter("toTimeAmPm", function(value) {
	if (!value) return "";
	return dayjs(value).format("LT");
});

Vue.filter("userProfileImg", function(value) {
	if (value == null || value.length === 0) return "/assets/images/default-user.png";
	return value;
});

Vue.filter("currency", function(amount) {
	if (amount == null) return "";
	const currencySymbol = "$";
	const decimalCount = 2;
	const decimal = ".";
	const thousands = ",";
	const negativeSign = amount < 0 ? "-" : "";

	let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
	let j = i.length > 3 ? i.length % 3 : 0;

	return (
		currencySymbol +
		negativeSign +
		(j ? i.substr(0, j) + thousands : "") +
		i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
		(decimalCount
			? decimal +
			  Math.abs(amount - i)
					.toFixed(decimalCount)
					.slice(2)
			: "")
	);
});

Vue.filter("decimal", function(amount) {
	if (amount == null) return "";
	const decimalCount = 2;
	const decimal = ".";
	const negativeSign = amount < 0 ? "-" : "";

	let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
	let j = i.length > 3 ? i.length % 3 : 0;

	return (
		negativeSign +
		i +
		(decimalCount
			? decimal +
			  Math.abs(amount - i)
					.toFixed(decimalCount)
					.slice(2)
			: "")
	);
});

Vue.filter("boolToYesNo", function(value) {
	if (value == null || value.length === 0) return "-";
	return value ? "Yes" : "No";
});

Vue.filter("boolToActive", function(value) {
	if (value == null || value.length === 0) return "-";
	return value ? "Active" : "Inactive";
});

Vue.filter("phone", function(value) {
	// if input value is falsy eg if the user deletes the input, then just return
	if (!value) return value;

	// clean the input for any non-digit values.
	const phoneNumber = value.replace(/[^\d]/g, "");

	// phoneNumberLength is used to know when to apply our formatting for the phone number
	const phoneNumberLength = phoneNumber.length;

	// we need to return the value with no formatting if its less then four digits
	// this is to avoid weird behavior that occurs if you  format the area code to early
	if (phoneNumberLength < 4) return phoneNumber;

	// if phoneNumberLength is greater than 4 and less the 7 we start to return
	// the formatted number
	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
	}

	if (phoneNumberLength > 10) {
		const offset = phoneNumberLength - 10;
		return `+${phoneNumber.slice(0, offset)} (${phoneNumber.slice(offset, offset + 3)}) ${phoneNumber.slice(offset + 3, offset + 6)}-${phoneNumber.slice(offset + 6).trim()}`;
	}

	// finally, if the phoneNumberLength is greater then seven, we add the last
	// bit of formatting and return it.
	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6).trim()}`;
});

Vue.filter("colorNumberNameDisplay", function(colorObject) {
	if (colorObject == null || colorObject.ColorName === 0) return "-";
	let display = colorObject.ColorName.replace("<em>", "")
		.replace("</em>", "")
		.replace("&trade;", "");
	if (colorObject.ColorNumber.length > 0) display += " (" + colorObject.ColorNumber + ")";
	return display;
});
