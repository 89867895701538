export default [
	{
		path: "/checkout:returnUrl?",
		name: "checkout",
		component: () => import(/* webpackChunkName: "account" */ "@/views/checkout/vCheckout.vue"),
		meta: { title: "Checkout", auth: true, roles: null },
	},
	{
		path: "/cardiframe",
		name: "cardiframe",
		component: () => import(/* webpackChunkName: "account" */ "@/components/creditcard/cCardNew.vue"),
		meta: { title: "WorldPay iFrame test", auth: true, roles: null },
	},
];
