<template>
	<b-sidebar id="side-drawer" title="" shadow no-header>
		<h4 id="sidebar-no-header-title" class="text-right">
			<font-awesome-icon icon="times" @click="closeMenu" />
		</h4>
		<div class="panel-group category-products">
			<div v-show="!$store.getters.isAuthenticated()" class="side-logout">
				<router-link to="/login"><font-awesome-icon icon="user-lock" /> {{ $t("Login") }}</router-link>
			</div>
			<div v-show="$store.getters.isAuthenticated()" class="side-logout">
				<router-link to="/logout"><font-awesome-icon icon="sign-out-alt" /> {{ $t("Logout") }}</router-link>
			</div>
			<!-- <cAccordianRetailers initialiaze="collapsed" v-if="multiRetailer"></cAccordianRetailers> -->
			<cAccordianRouterLink v-bind:items="accountGroup" initialiaze="open"></cAccordianRouterLink>
			<cAccordianRouterLink v-bind:items="searchOptions" initialiaze="collapsed"></cAccordianRouterLink>
			<cAccordianRouterLink v-bind:items="contractorGroup" :initialiaze="$store.getters.isContractor ? 'open' : 'collapsed'"></cAccordianRouterLink>
			<cAccordianRouterLink v-if="$store.getters.isRetailerAdmin" v-bind:items="contractorAdmin" initialiaze="open"></cAccordianRouterLink>
		</div>
		<div v-if="$runningLocal" class="no-print">
			<div>isAuthenticated:{{ $store.getters.isAuthenticated() == true }}</div>
			<div>isContractor:{{ $store.getters.isContractor }}</div>
			<div>isAllowedPayments:{{ $store.getters.isAllowedPayments }}</div>
			<div>isPrimary:{{ $store.getters.isPrimary }}</div>
			<div>isRetailerAdmin:{{ $store.getters.isRetailerAdmin }}</div>
			<div>portalAvailable:{{ $store.getters.portalAvailable }}</div>
		</div>
	</b-sidebar>
</template>

<script>
import cAccordianRetailers from "@/components/shared/cAccordianRetailers.vue";
import cAccordianRouterLink from "@/components/shared/cAccordianRouterLink.vue";
import * as _publicService from "@/services/publicService";

export default {
	name: "cAppLeftDrawer",
	components: { cAccordianRetailers, cAccordianRouterLink },
	props: [],
	data: () => ({
		accountGroup: {
			topLevel: { name: "My Account", routeName: null, parameterId: null, parameter: null },
			children: [
				{ name: "Profile", routeName: "profile", parameterId: null, parameter: null },
				{ name: "Order History", routeName: "orderhistory", parameterId: null, parameter: null },
				{ name: "My Favorites", routeName: "favorites", parameterId: null, parameter: null },
				{ name: "Log out", routeName: "logout", parameterId: null, parameter: null },
			],
		},
		searchOptions: {
			topLevel: { name: "Products", routeName: "productcategories", parameterId: null, parameter: null },
			children: [],
		},
	}),
	methods: {
		load() {
			let queryStringParameter = {
				Department: "",
				Class: "",
				Line: "",
				Brand: "",
				SearchTerm: "",
			};

			_publicService.getAllProductSearchOptions(queryStringParameter, (response) => {
				//console.info("cAppLeftDrawer|load|getAllProductSearchOptions fired!");
				if (response.Success) {
					// this.searchOptions.children.push({
					// 	name: "Department",
					// 	routeName: null,
					// 	parameterId: null,
					// 	parameter: null,
					// 	url: null,
					// });
					// response.Departments.forEach((e) => {
					// 	//"/products?" + this.title.toLowerCase() + "=" + i.Display;
					// 	let url = "/filter?dept=" + e.Id;
					// 	this.searchOptions.children.push({
					// 		name: e.Display,
					// 		routeName: "products",
					// 		parameterId: null,
					// 		parameter: null,
					// 		url: url,
					// 	});
					// });
					// this.searchOptions.children.push({
					// 	name: "Class",
					// 	routeName: null,
					// 	parameterId: null,
					// 	parameter: null,
					// 	url: null,
					// });
					// response.Classes.forEach((e) => {
					// 	let url = "/filter?class=" + e.Id;
					// 	this.searchOptions.children.push({
					// 		name: e.Display,
					// 		routeName: "products",
					// 		parameterId: null,
					// 		parameter: null,
					// 		url: url,
					// 	});
					// });
					// this.searchOptions.children.push({
					// 	name: "Line",
					// 	routeName: null,
					// 	parameterId: null,
					// 	parameter: null,
					// 	url: null,
					// });
					// response.Lines.forEach((e) => {
					// 	let url = "/products?line=" + e.Id;
					// 	this.searchOptions.children.push({
					// 		name: e.Display,
					// 		routeName: "products",
					// 		parameterId: null,
					// 		parameter: null,
					// 		url: url,
					// 	});
					// });
					// this.searchOptions.children.push({
					// 	name: "Brand",
					// 	routeName: null,
					// 	parameterId: null,
					// 	parameter: null,
					// 	url: null,
					// });
					// response.Brands.forEach((e) => {
					// 	let url = "/products?brand=" + e.Id;
					// 	this.searchOptions.children.push({
					// 		name: e.Display,
					// 		routeName: "products",
					// 		parameterId: null,
					// 		parameter: null,
					// 		url: url,
					// 	});
					// });
				}
			});
		},
		closeMenu() {
			this.$store.commit("showMenu", false);
			let el = document.getElementById("side-drawer");
			if (el) el.classList.remove("open");
		},
	},
	computed: {
		contractorGroup: function () {
			let items = {
				topLevel: { name: "Contractor Portal", routeName: null, parameterId: null, parameter: null },
				children: [],
			};
			if (!this.$store.getters.isContractor) {
				items.children.push({
					name: "Your account is not assocated with a contractor account.",
					routeName: null,
					parameterId: null,
					parameter: null,
				});

				return items;
			}
			items.children.push({ name: "Invoices", routeName: "contractorinvoices", parameterId: null, parameter: null });
			items.children.push({
				name: "Statements",
				routeName: "contractorstatements",
				parameterId: null,
				parameter: null,
			});
			items.children.push({ name: "Search", routeName: "contractorsearch", parameterId: null, parameter: null });

			if (this.$store.getters.isAllowedPayments) {
				items.children.push({
					name: "Payments",
					routeName: "contractorpayment",
					parameterId: null,
					parameter: null,
				});
				items.children.push({
					name: "Regulatory",
					routeName: "contractorregulatory",
					parameterId: null,
					parameter: null,
				});
			}
			if (this.$store.getters.isPrimary) {
				items.children.push({
					name: "Users",
					routeName: "contractorusers",
					parameterId: null,
					parameter: null,
				});
			}
			return items;
		},
		contractorAdmin: function () {
			let items = {
				topLevel: { name: "Admin", routeName: null, parameterId: null, parameter: null },
				children: [],
			};
			/* 			if (this.$store.getters.isRetailerAdmin) {
				items.children.push({ name: "Users", routeName: "contractorusers", parameterId: null, parameter: null });
			} */
			if (this.$store.getters.isPrimary) {
				items.children.push({
					name: "Logo",
					routeName: "contractorlogo",
					parameterId: null,
					parameter: null,
				});
				items.children.push({
					name: "Banner Ads",
					routeName: "contractorbannerads",
					parameterId: null,
					parameter: null,
				});
				items.children.push({
					name: "Splash Page",
					routeName: "contractorsplashpage",
					parameterId: null,
					parameter: null,
				});
				items.children.push({
					name: "Home Page",
					routeName: "contractorhomepage",
					parameterId: null,
					parameter: null,
				});
			}
			return items;
		},
		multiRetailer: function () {
			return this.$store.getters.multiRetailer;
		},
	},
	watch: {},
	mounted: function () {
		this.load();
	},
};
</script>

<style>
.side-logout {
	position: absolute;
	top: 5px;
	left: 15px;
}
.side-logout a {
	color: var(--buttonBackgroundColor);
}
.category-products {
	padding-bottom: 20px;
}

.category-products .panel {
	background-color: #ffffff;
	border: 0px;
	border-radius: 0px;
	box-shadow: none;
	margin-bottom: 10px;
	padding-bottom: 10px;
}

.category-products .panel-default .panel-heading {
	background-color: #ffffff;
	border: 0 none;
	color: #ffffff;
	padding: 5px 10px;
}
.category-products .panel-default h4.panel-title {
	margin: 0;
}
.category-products .panel-default .panel-heading .panel-title a {
	color: #696763;
	text-decoration: none;
	text-transform: uppercase;
}

.panel-group .panel-heading + .panel-collapse .panel-body {
	border-top: 0 none;
}

.category-products .badge {
	position: relative;
	top: 5px;
}

.category-products .panel-body ul {
	padding-left: 20px;
	margin: 0;
}

.category-products .panel-body ul li a {
	color: var(--baseColor);
	/* font-size: 12px; */
	text-transform: uppercase;
}
.category-products .panel-body ul li a:hover {
	color: var(--linkHoverColor);
}
</style>
