<template>
  <b-sidebar id="cart-drawer" title="" right shadow no-header class="app-cart">
    <div class="top">
      <span class="top-left" v-b-toggle.cart-drawer>{{ $t("Close Cart") }}</span>
      <!-- <router-link to="/checkout"> -->
      <div @click="navigateToCheckout">
        <div class="top-right" v-if="cartHasItems">
          {{ $t("Check Out") }}
          <font-awesome-icon icon="shopping-cart" />
          <font-awesome-icon icon="arrow-right" />
        </div>
      </div>
      <!-- </router-link> -->
    </div>

    <div class="scroll">
      <div v-if="!cartHasItems" class="cart-item">{{ $t("CartEmpty") }}</div>
      <div v-for="c in cart" :key="c.CartId" class="cart-item">
        <font-awesome-icon icon="trash" @click="removeFromCart(c)" class="pull-right" />
        <div class="image-container">
          <img :src="c.ImageSrc" alt="" />
          <div v-if="c.RGB" v-bind:style="getSelectedColorStyle(c.RGB)">&nbsp;</div>
        </div>
        <p class="description">{{ c.ShortDescription }}</p>
        <p class="upc" v-if="isReorder(c)">{{ $t("CartReorder") }}</p>
        <p class="upc" v-if="c.Prompts">
          <span v-for="(p, index) in c.Prompts" :key="c.CartId + index">
            {{ $t(p.Label) }}:
            <input v-if="p.Label && p.Label.toLowerCase() == 'location'" type="text" v-model="p.Value" v-on:blur="locationChanged(c.CartId, p)" style="width: 200px" />
            <span v-else v-html="p.Value"></span>
            <br />
          </span>
        </p>
        <p class="upc">{{ $t("UPC") }}: {{ c.UPC }}</p>
        <p class="name">{{ $t("Brand") }}: {{ c.Brand }}</p>
        <p>
          Qty.
          <button class="quantity-input-button" @click="decreaseQuantity(c)">-</button>
          <label class="quantity-input">{{ c.Quantity }}</label>
          <button class="quantity-input-button" @click="increaseQuantity(c)">+</button>
        </p>
        <p class="price">{{ c.UnitPrice | currency }} /{{ c.UomName }}</p>
      </div>
    </div>
    <template>
      <div class="bottom" @click="emptyCart()" v-if="cartHasItems" v-b-toggle.cart-drawer>
        {{ $t("Empty Cart") }}
        <font-awesome-icon icon="trash" />
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { eventBus } from "@/main";
import * as _cartService from "@/services/cartService";

export default {
  name: "cAppCart",
  components: {},
  props: [],
  data: () => ({
    cart: [],
    returnUrl: "/",
    qtyWarning: "",
  }),
  methods: {
    getCart() {
      _cartService.getCart((response) => {
        if (response.Success) {
          this.cart = response.Cart;
          eventBus.$emit("cartQtyUpdated", response.Cart.length);
          let products = [];
          this.cart.forEach((p) => {
            products.push({ ProductId: p.ProductId, Quantity: p.Quantity });
          });
          eventBus.$emit("cartUpdated", products);
        }
      });
    },
    addToCart(item) {
      //we need to clone the product, otherwise it will "add" the line number to the product listing
      const cartItem = { ImageSrc: item.ImageSrc, ...item.Product };
      _cartService.addToCart(cartItem, (response) => {
        if (response.Success) {
          const qtyExceded = item.Product.ShowQuantity && item.Product.Quantity > item.Product.QuantityOnHand;
          //console.info("cAppCart|addToCard|qtyExceded:", qtyExceded);
          let message = "";
          if (response.UnitPrice != item.Product.RetailPrice) message = this.$t("SpecialPricing");
          message += cartItem.ShortDescription != null ? cartItem.ShortDescription : this.$t("product");
          message += this.$t("AddedToCart");
          if (qtyExceded) message += "<br>" + this.qtyWarning;
          this.$store.commit("dispatchMessage", {
            color: qtyExceded ? "warning" : "success",
            text: message,
          });
          this.getCart();
        }
      });
    },
    close() {
      //this.$store.commit("showCart", false);
      //v-b-toggle.cart-drawer
      this.$root.$emit("bv::toggle", "cart-drawer");
    },
    emptyCart(showMessage = true) {
      _cartService.emptyCart((response) => {
        if (response.Success) {
          if (showMessage) {
            this.$store.commit("dispatchMessage", {
              color: "success",
              text: this.$t("CartEmptied"),
            });
          }
          this.getCart();
          eventBus.$emit("productRemovedFromCart", "all");
          this.close();
        }
      });
    },
    locationChanged(cartId, prompt) {
      //console.log("cart item location updated:", cartId, prompt);
      _cartService.updateItemLocation(cartId, prompt.Value, (response) => {});
    },
    isReorder(item) {
      return item.IsTinted === true && item.od_id;
    },
    removeFromCart(item) {
      _cartService.removeFromCart(item.CartId, (response) => {
        if (response.Success) {
          this.getCart();
          eventBus.$emit("productRemovedFromCart", item.ProductId);
        }
      });
    },
    updateQuantity(product) {
      const unitPrice = product.UnitPrice;
      _cartService.addToCart(product, (response) => {
        if (response.Success) {
          let products = [{ ProductId: product.ProductId, Quantity: product.Quantity }];
          eventBus.$emit("cartUpdated", products);

          const qtyExceded = product.ShowQuantity && product.Quantity > product.QuantityOnHand;
          console.info("cAppCart|addToCard|qtyExceded:", qtyExceded);
          let message = "";
          if (response.UnitPrice != unitPrice) {
            message = this.$t("SpecialPricingApplied");
            product.UnitPrice = response.UnitPrice;
          }
          if (qtyExceded) message.length > 0 ? (message += "<br>" + this.qtyWarning) : (message = this.qtyWarning);
          if (message.length > 0) {
            this.$store.commit("dispatchMessage", {
              color: qtyExceded ? "warning" : "success",
              text: message,
            });
          }
        }
      });
    },
    updatePrices() {
      if (this.cart.length === 0) return;
      let data = { Products: [] };
      this.cart.forEach((p) => {
        data.Products.push({ LineNumber: p.LineNumber, UPC: p.UPC, Quantity: p.Quantity, Price: p.UnitPrice });
      });
      _cartService.getUnitPrices(data, (response) => {
        if (response.Success) {
          //console.info("cAppCard|updatePrices|response|Products:", response.Products);
          let itemsRepriced = false;
          response.Products.forEach((p) => {
            let i = this.cart.find((c) => c.UPC === p.UPC);
            if (i && p.Price !== null && i.UnitPrice !== p.Price) {
              i.UnitPrice = p.Price;
              itemsRepriced = true;
              _cartService.addToCart(i, (response) => {});
            }
          });
          if (itemsRepriced) {
            this.$store.commit("dispatchMessage", {
              color: "success",
              text: this.$t("CartRepriced"),
            });
          }
        }
      });
    },
    increaseQuantity(product) {
      product.Quantity += 1;
      this.updateQuantity(product);
    },
    decreaseQuantity(product) {
      if (product.Quantity === 1) return;
      product.Quantity -= 1;
      this.updateQuantity(product);
    },
    selectAll(element) {
      const input = document.getElementById(element);
      if (input) {
        //Number inputs don't support the setSelectionRange, so we
        //have to fake it out by changing type to text, then back
        const isNumberInput = input.type == "number";
        if (isNumberInput) input.type = "text";
        input.setSelectionRange(0, input.value.length);
        if (isNumberInput) input.type = "number";
      }
    },
    getSelectedColorStyle(thisRgb) {
      if (thisRgb == null) return "";
      let colorStyle = {};
      colorStyle.backgroundColor = thisRgb;
      //if the RGB is dark then we need to set the color to white...
      let rgb = thisRgb.replace("rgb(", "").replace(")", "");
      let rgbArray = rgb.split(",");
      let textColor = "white";
      rgbArray.forEach((e) => {
        if (e > 140) textColor = "black";
      });
      colorStyle.color = textColor;
      return colorStyle;
    },
    navigateToCheckout() {
      this.$router.push({ name: "checkout", params: { returnUrl: this.returnUrl } });
    },
  },
  computed: {
    cartHasItems: function () {
      return this.cart.length > 0;
    },
  },
  watch: {},
  created() {
    //when wiring up event buses you need to dispose of any existing event listeners
    //with the "off" - otherwise you could end up with the event added more than once
    //and it would execute multipe times for each call
    eventBus.$off("addToCart");
    eventBus.$on("addToCart", (data) => {
      this.addToCart(data);
    });
    eventBus.$off("emptyCart");
    eventBus.$on("emptyCart", (showMessage) => {
      this.emptyCart(showMessage);
    });
    eventBus.$off("retailerSelected");
    eventBus.$on("retailerSelected", (showMessage) => {
      //this.getCart();
    });
    eventBus.$off("storeSelected");
    eventBus.$on("storeSelected", (storeId) => {
      this.getCart();
    });
    eventBus.$on("login", () => {
      this.updatePrices();
    });
  },
  mounted: function () {
    this.qtyWarning = this.$t("QtyWarning");
    if (document.location.href.indexOf("productdetail") > -1) this.returnUrl = document.location.href.substring();
    else this.returnUrl = "/";
    //console.log("cAppCart|mounted|returnUrl:", this.returnUrl);
    this.getCart();
  },
  destroyed: function () {
    eventBus.$off("addToCart");
    eventBus.$off("emptyCart");
    eventBus.$off("retailerSelected");
    eventBus.$off("storeSelected");
  },
};
</script>

<style></style>
