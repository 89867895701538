<template>
	<div>
		<b-modal id="modal-retailer-select-warning" scrollable size="lg">
			<template #modal-header="{ close }">
				<h5 class="accentColor2">{{ $t("ChangeRetailerLogIn") }}</h5>
			</template>
			<div class="row text-info" v-if="logInRetailer != null">
				<div class="col-md-3">{{ $t("CurrentRetailer") }}:</div>
				<div class="col-md-9">{{ selectedRetailer.RetailerId }} - {{ selectedRetailer.RetailerName }}</div>
			</div>
			<div class="row mb-3 text-success" v-if="logInRetailer != null">
				<div class="col-md-3">{{ $t("NewRetailer") }}:</div>
				<div class="col-md-9">{{ logInRetailer.RetailerId }} - {{ logInRetailer.RetailerName }}</div>
			</div>
			<div class="alert alert-danger" v-if="message">{{ message }}</div>
			<form class="input-form modal-login-form" onsubmit="event.preventDefault();">
				<cValidatedInputNoAutocomplete class="full-width" v-model="$v.form.Email" label="" :placeholder="$t('Email')" />
				<cValidatedInputNoAutocomplete class="full-width" v-model="$v.form.Password" type="password" label="" :placeholder="$t('Password')" />
			</form>
			<template #modal-footer>
				<div class="row">
					<div class="col-md-8 col-sm-12">
						<p class="float-left accentColor2 small">{{ $t("ChangeStoreEmptyCart") }}</p>
					</div>
					<div class="col-md-4 col-sm-12">
						<button class="btn ml-3 float-right" @click="logIntoRetailer()">{{ $t("Login") }}</button>
						<button class="btn float-right" @click="warningCancel()">{{ $t("Cancel") }}</button>
					</div>
				</div>
			</template>
		</b-modal>
		<div style="max-width:600px;">
			<div class="store-div" :class="{ selected: r === selectedRetailer }" v-for="r in retailers" :key="r.RetailerId">
				<div class="distance">
					<img class="retailer-logo" :src="getLogoSrc(r)" />
				</div>
				<div class="details">
					<div class="title">
						{{ r.RetailerId }} - {{ r.RetailerName }} <br />
						<template v-if="!isSelectedRetailer(r.RetailerId)">
							<button class="btn" @click="removeRetailer(r)">{{ $t("Remove") }}</button>
							<button class="btn ml-5" @click="makeMine(r)">{{ $t("Select") }}</button>
						</template>
						<font-awesome-icon v-if="isSelectedRetailer(r.RetailerId)" icon="check" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import cValidatedInput from "@/components/shared/cValidatedInput";
import cValidatedInputNoAutocomplete from "@/components/shared/cValidatedInputNoAutocomplete";

import * as _publicService from "@/services/publicService";
import * as _userService from "@/services/userService";
import * as _utility from "@/utility";
import { eventBus } from "@/main";

//https://vuelidate.js.org/#sub-builtin-validators
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import { password } from "@/utility/validatorsCustom";
import * as _validation from "@/utility/validation";
import * as _config from "@/config";

export default {
	name: "cRetailerSelect",
	components: { cValidatedInput, cValidatedInputNoAutocomplete },
	props: {},
	data: () => ({
		logInRetailer: null,
		selectedRetailer: null,
		retailers: [],
		form: {
			Email: "",
			Password: "",
			RememberMe: false,
		},
		message: null,
	}),
	methods: {
		load() {
			_userService.getRetailers((response) => {
				console.info("cRetailerSelector|getRetailers|response:", response);
				if (response.Success) {
					this.retailers = response.Retailers;
					this.selectedRetailer = this.retailers.find((r) => r.RetailerId == this.$store.getters.retailerId);
					eventBus.$emit("reloadRetailers");
				}
			});
		},
		getLogoSrc: function(r) {
			if (!r.Base64EncodedString || r.Base64EncodedString.length < 100) return "/assets/shopping/images/logo.png";
			return "data:image/jpeg;base64," + r.Base64EncodedString;
		},
		isSelectedRetailer: function(retailerId) {
			if (!this.selectedRetailer) return false;
			return this.selectedRetailer.RetailerId == retailerId;
		},
		makeMine: function(retailer) {
			//If they are logged in prompt to log into new retailer,
			//otherwise just switch
			if (this.$store.getters.isAuthenticated()) {
				this.message = null;
				this.form.Email = "";
				this.form.Password = "";
				this.$v.form.$reset();
				this.logInRetailer = retailer;
				this.$bvModal.show("modal-retailer-select-warning");
			} else {
				this.switchRetailer(retailer);
			}
		},
		switchRetailer: function(retailer) {
			console.info("switchRetailer|retailer:", retailer);
			this.selectedRetailer = retailer;
			this.$store.dispatch("saveRetailerId", retailer.RetailerId).then((r) => {
				this.$emit("retailerSelected");
				eventBus.$emit("retailerSelected", retailer);
				eventBus.$emit("storeSelected", retailer.StoreId);
			});
		},
		logIntoRetailer: function() {
			this.$v.form.$touch();
			if (this.$v.form.$anyError) {
				return;
			}
			this.message = null;
			//Set the app retailer Id before attempting log in...
			this.$store.dispatch("saveRetailerId", this.logInRetailer.RetailerId).then((r) => {
				_userService.logIn(this.form, (response) => {
					if (!response.Success) {
						//reset the retailer Id..
						this.$store.dispatch("saveRetailerId", this.selectedRetailer.RetailerId);
						this.message = response.Message;
					} else {
						this.$store.commit("dispatchMessage", {
							color: "success",
							text: this.$t("Retailer") + " " + this.logInRetailer.RetailerId + " - " + this.logInRetailer.RetailerName + " " + this.$t("LoginSuccess"),
							timeout: 3000,
						});
						this.$bvModal.hide("modal-retailer-select");
						this.$bvModal.hide("modal-retailer-select-warning");
						eventBus.$emit("retailerSelected", this.logInRetailer);
						this.$router.push("/retailer/" + this.logInRetailer.RetailerId);
					}
				});
			});
		},
		removeRetailer: function(retailer) {
			_userService.removeRetailer(retailer.RetailerId, (response) => {
				eventBus.$emit("retailerRemoved", retailer);
			});
		},
		warningCancel: function() {
			this.$bvModal.hide("modal-retailer-select-warning");
		},
		formIsValid(f) {
			return _validation.formIsValid(f);
		},
		fieldIsValid(p) {
			return _validation.validationState(p);
		},
		validationErrorMessage(p) {
			return _validation.validationErrorMessage(p);
		},
	},
	validations: {
		form: {
			Email: { required, email },
			Password: { required },
		},
		formNewAccount: {
			Email: {
				required,
				email,
			},
			Password: { password },
		},
	},
	computed: {},
	watch: {},
	created() {
		//Since this component is loaded more than once don't remove event
		//eventBus.$off("retailerRemoved");
		eventBus.$on("retailerRemoved", (retailer) => {
			let exist = this.retailers.find((r) => r.RetailerId == retailer.RetailerId);
			if (exist) this.retailers.splice(this.retailers.indexOf(retailer), 1);
		});

		//eventBus.$off("retailerSelected");
		eventBus.$on("retailerSelected", (retailer) => {
			if (retailer != this.selectedRetailer) {
				this.selectedRetailer = retailer;
			}
		});
	},
	mounted: function() {
		this.load();
	},
	destroyed: function() {
		//Since this component is loaded more than once don't remove event
		//eventBus.$off("retailerRemoved");
		//eventBus.$off("retailerSelected");
	},
};
</script>

<style>
.retailer-select {
	max-width: 600px;
}
.retailer-logo {
	width: 90px;
}
.modal-login-form {
	width: 300px;
	margin-left: auto;
	margin-right: auto;
}
</style>
