import * as _config from "@/config";
import * as _utility from "@/utility";

const dbName = _config.cacheDb;
const dbVersion = 10;

export function clear() {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				console.log("cacheIndexDbService|clear triggered!");
				let tables = ["HomePageCards", "PaintColors", "PaintFamilies", "Retailers", "SearchOptionBrands", "SearchOptionClasses", "SearchOptionDepartments", "SearchOptionLines", "SplashPages"];
				let db = responseDb.Db;
				tables.forEach((t) => {
					let trans = db.transaction(t, "readwrite");
					let st = trans.objectStore(t);
					st.clear();
				});
				return resolve({
					Success: true,
					FailureInformation: "",
				});
			})
			.catch((error) => {
				console.error(error);
				return reject({
					Success: false,
					FailureInformation: error,
				});
			});
	});
}

/*Product Search */
export function getAllProductSearchOptions() {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to get SearchOptions from " + dbName,
					Brands: [],
					Classes: [],
					Departments: [],
					Lines: [],
				};
				let cnt = 0;
				let total = 4;
				_getSearchOptionTableByDisplay("SearchOptionBrands", responseDb.Db).then((responseGet) => {
					if (!responseGet.Success) {
						return reject(responseGet);
					}
					cnt = cnt + 1;
					response.Brands = responseGet.Records;
					if (cnt == total) {
						response.Success = true;
						return resolve(response);
					}
				});
				_getSearchOptionTableByDisplay("SearchOptionClasses", responseDb.Db).then((responseGet) => {
					if (!responseGet.Success) {
						return reject(responseGet);
					}
					cnt = cnt + 1;
					response.Classes = responseGet.Records;
					if (cnt == total) {
						response.Success = true;
						return resolve(response);
					}
				});
				_getSearchOptionTableByDisplay("SearchOptionDepartments", responseDb.Db).then((responseGet) => {
					if (!responseGet.Success) {
						return reject(responseGet);
					}
					cnt = cnt + 1;
					response.Departments = responseGet.Records;
					if (cnt == total) {
						response.Success = true;
						return resolve(response);
					}
				});
				_getSearchOptionTableByDisplay("SearchOptionLines", responseDb.Db).then((responseGet) => {
					if (!responseGet.Success) {
						return reject(responseGet);
					}
					cnt = cnt + 1;
					response.Lines = responseGet.Records;
					if (cnt == total) {
						response.Success = true;
						return resolve(response);
					}
				});
			})
			.catch((error) => {
				return reject({
					Success: false,
					FailureInformation: error.Message,
					Brands: [],
					Classes: [],
					Departments: [],
					Lines: [],
				});
			});
	});
}

export function saveAllProductSearchOptions(options) {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: true,
					FailureInformation: "",
				};
				let cnt = 0;
				let total = 4;
				_saveSearchOptionTable(options.Brands, "SearchOptionBrands", responseDb.Db).then((responseSave) => {
					if (!responseSave.Success) {
						return reject(responseSave);
					}
					cnt = cnt + 1;
					if (cnt == total) {
						return resolve(response);
					}
				});
				_saveSearchOptionTable(options.Classes, "SearchOptionClasses", responseDb.Db).then((responseSave) => {
					if (!responseSave.Success) {
						return reject(responseSave);
					}
					cnt = cnt + 1;
					if (cnt == total) {
						return resolve(response);
					}
				});
				_saveSearchOptionTable(options.Departments, "SearchOptionDepartments", responseDb.Db).then((responseSave) => {
					if (!responseSave.Success) {
						return reject(responseSave);
					}
					cnt = cnt + 1;
					if (cnt == total) {
						return resolve(response);
					}
				});
				_saveSearchOptionTable(options.Lines, "SearchOptionLines", responseDb.Db).then((responseSave) => {
					if (!responseSave.Success) {
						return reject(responseSave);
					}
					cnt = cnt + 1;
					if (cnt == total) {
						return resolve(response);
					}
				});
			})
			.catch((error) => {
				return reject({
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

/*Paint Colors */
export function getPaintAllColors(manufacturerId, isExterior) {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to get Paint colors from " + dbName,
					Colors: [],
				};
				let db = responseDb.Db;
				let trans = db.transaction(["PaintColors"], "readonly");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("PaintColors");
				store.onerror = (e) => {
					return reject(response);
				};
				let query = store.index("ByManufacturerId").getAll(manufacturerId);
				query.onsuccess = (e) => {
					let records = [];
					if (e.target.result != null) {
						records = isExterior === false ? e.target.result : e.target.result.filter((c) => c.ExteriorAvailability !== "Unavailable");
					}
					response.Success = true;
					response.FailureInformation = "";
					response.Colors = records;
					return resolve(response);
				};
				query.onerror = (e) => {
					return reject(response);
				};
			})
			.catch((error) => {
				return reject({
					Success: false,
					FailureInformation: error.Message,
					Palettes: [],
				});
			});
	});
}

export function savePaintAllColors(manufacturerId, colors) {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to save Paint colors",
				};
				let db = responseDb.Db;
				getPaintAllColors(manufacturerId, null).then((getColorResponse) => {
					//delete all existing colors...
					if (getColorResponse.Success) {
						let transDelete = db.transaction(["PaintColors"], "readwrite");
						transDelete.onerror = (e) => {
							return reject(response);
						};
						getColorResponse.Colors.forEach((c) => {
							let storeDelete = transDelete.objectStore("PaintColors");
							storeDelete.onerror = (e) => {
								return reject(response);
							};
							storeDelete.delete(c.ColorName);
						});
					}
					//add new ones..
					let trans = db.transaction(["PaintColors"], "readwrite");
					trans.onerror = (e) => {
						return reject(response);
					};
					let store = trans.objectStore("PaintColors");
					store.onerror = (e) => {
						return reject(response);
					};
					colors.forEach((p) => {
						let query = store.put(p);
						query.onerror = (e) => {
							response.FailureInformation = "There was an error inserting a color..";
							return reject(response);
						};
					});
					_utility.saveToSession("PaintColors." + manufacturerId, new Date());
					response.Success = true;
					return resolve(response);
				});
			})
			.catch((error) => {
				return reject({
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

export function getPaintFamily(manufacturerId, family) {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to get Paint family '" + family + "' from " + dbName,
					Family: null,
				};
				let db = responseDb.Db;
				let trans = db.transaction(["PaintFamilies"], "readonly");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("PaintFamilies");
				store.onerror = (e) => {
					return reject(response);
				};
				let query = store.getAll(family);
				query.onsuccess = (e) => {
					response.Success = true;
					response.FailureInformation = "";
					response.Family = e.target.result.find((f) => f.ManufacturerId == manufacturerId);
					return resolve(response);
				};
				query.onerror = (e) => {
					return reject(response);
				};
			})
			.catch((error) => {
				return reject({
					Success: false,
					FailureInformation: error.Message,
					Family: null,
				});
			});
	});
}

export function getPaintFamilies(manufacturerId) {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to get Paint families from " + dbName,
					Palettes: [],
				};
				let db = responseDb.Db;
				let trans = db.transaction(["PaintFamilies"], "readonly");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("PaintFamilies");
				store.onerror = (e) => {
					return reject(response);
				};
				let query = store.index("ByManufacturerId").getAll(manufacturerId);
				query.onsuccess = (e) => {
					response.Success = true;
					response.FailureInformation = "";
					response.Palettes = e.target.result != null ? e.target.result : [];
					return resolve(response);
				};
				query.onerror = (e) => {
					return reject(response);
				};
			})
			.catch((error) => {
				return reject({
					Success: false,
					FailureInformation: error.Message,
					Palettes: [],
				});
			});
	});
}

export function savePaintFamilies(manufacturerId, families) {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to save Paint Families",
				};
				let db = responseDb.Db;
				getPaintFamilies(manufacturerId).then((getFamiliesResponse) => {
					//delete existing families first...
					if (getFamiliesResponse.Success) {
						let transDelete = db.transaction(["PaintFamilies"], "readwrite");
						transDelete.onerror = (e) => {
							return reject(response);
						};
						getFamiliesResponse.Palettes.forEach((c) => {
							let storeDelete = transDelete.objectStore("PaintFamilies");
							storeDelete.onerror = (e) => {
								return reject(response);
							};
							storeDelete.delete(c.Name);
						});
					}
					//add back new ones
					let trans = db.transaction(["PaintFamilies"], "readwrite");
					trans.onerror = (e) => {
						return reject(response);
					};
					let store = trans.objectStore("PaintFamilies");
					store.onerror = (e) => {
						return reject(response);
					};
					families.forEach((p) => {
						let query = store.put(p);
						query.onerror = (e) => {
							response.FailureInformation = "There was an error inserting a family..";
							return reject(response);
						};
					});
					_utility.saveToSession("PaintFamilies." + manufacturerId, new Date());
					response.Success = true;
					return resolve(response);
				});
			})
			.catch((error) => {
				return reject({
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

export function getPaintFamilyColors(manufacturerId, family, isExterior) {
	return new Promise((resolve, reject) => {
		if (family == null)
			return resolve({
				Success: false,
				FailureInformation: "Family not supplied",
				Colors: [],
			});
		let response = {
			Success: false,
			FailureInformation: "Unable to get Colors for " + family,
			Colors: [],
		};
		getPaintFamily(manufacturerId, family).then((familyResponse) => {
			if (!familyResponse.Success) return reject(response);
			if (familyResponse.Family.ColorList != null) {
				getPaintAllColors(manufacturerId, null).then((getColorsResponse) => {
					if (!getColorsResponse.Success) reject(response);
					let records = [];
					familyResponse.Family.ColorList.forEach((fc) => {
						let color = getColorsResponse.Colors.find((c) => c.ColorNumber === fc);
						if (color) records.push(color);
					});
					//console.log("found " + records.length + " colors for " + family.Name + " family");
					response.Success = true;
					response.FailureInformation = "";
					response.Colors = records;
					return resolve(response);
				});
				//records = isExterior === false ? familyResponse.Family.Colors : familyResponse.Family.Colors.filter((c) => c.ExteriorAvailability !== "Unavailable");
			}
		});
	});
}

export function savePaintFamilyColors(manufacturerId, family, colors) {
	return new Promise((resolve, reject) => {
		getPaintFamily(manufacturerId, family).then((familyResponse) => {
			_getDb()
				.then((responseDb) => {
					let response = {
						Success: false,
						FailureInformation: "Unable to save Paint Family Colors",
					};
					let db = responseDb.Db;
					let trans = db.transaction(["PaintFamilies"], "readwrite");
					trans.onerror = (e) => {
						return reject(response);
					};
					let store = trans.objectStore("PaintFamilies");
					store.onerror = (e) => {
						return reject(response);
					};
					familyResponse.Family.Colors = colors;
					let query = store.put(familyResponse.Family);
					query.onsuccess = (e) => {
						//set the session storage flag so that we know the last time we saved data
						_utility.saveToSession("PaintFamilyColors." + manufacturerId, new Date());
						response.Success = true;
						response.FailureInformation = "";
						return resolve(response);
					};
					query.onerror = (e) => {
						return reject(response);
					};
				})
				.catch((error) => {
					return reject({
						Success: false,
						FailureInformation: error.Message,
					});
				});
		});
	});
}

/*Home Page Cards */
export function getHomePageCards(retailerId) {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to get Home Page Cards from " + dbName,
					HomePageCards: [],
				};
				let db = responseDb.Db;
				let trans = db.transaction(["HomePageCards"], "readonly");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("HomePageCards");
				store.onerror = (e) => {
					return reject(response);
				};
				let query = store.index("ByRetailerId").getAll(retailerId);
				query.onsuccess = (e) => {
					let records = [];
					if (e.target.result != null) {
						records = e.target.result.sort((a, b) => {
							return a.SortOrder - b.SortOrder;
						});
					}
					response.Success = true;
					response.FailureInformation = "";
					response.HomePageCards = records;
					return resolve(response);
				};
				query.onerror = (e) => {
					return reject(response);
				};
			})
			.catch((error) => {
				return reject({
					Success: false,
					FailureInformation: error.Message,
					HomePageCards: [],
				});
			});
	});
}

export function deleteHomePageCard(homePageCardId) {
	return new Promise((resolve, reject) => {
		if (homePageCardId == null)
			return resolve({
				Success: true,
				FailureInformation: "",
			});

		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to remove home page card",
				};
				let db = responseDb.Db;
				let trans = db.transaction(["HomePageCards"], "readwrite");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("HomePageCards");
				store.onerror = (e) => {
					return reject(response);
				};
				let query = store.delete(homePageCardId);
				query.onsuccess = (e) => {
					//console.log("cacheIndexDBService|deleteHomePageCard: success!", homePageCardId);
					response.Success = true;
					response.FailureInformation = "";
					return resolve(response);
				};
				query.onerror = (e) => {
					console.error("cacheIndexDBService|deleteHomePageCard: query.onerror", e);
					return reject(response);
				};
			})
			.catch((error) => {
				console.error("cacheIndexDBService|deleteHomePageCard: catch triggered", error);
				return reject({
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

export function saveHomePageCards(retailerId, homePageCards) {
	//console.log("saveCart: making promise");
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to save Home Page Cards",
				};
				let db = responseDb.Db;
				let trans = db.transaction(["HomePageCards"], "readwrite");
				trans.onerror = (e) => {
					//console.log("saveCart: promise rejected - transaction");
					return reject(response);
				};
				let storeClear = trans.objectStore("HomePageCards");
				var storeClearRequest = storeClear.clear();
				storeClearRequest.onsuccess = (e) => {
					let store = trans.objectStore("HomePageCards");
					store.onerror = (e) => {
						//console.log("saveCart: promise rejected - store");
						return reject(response);
					};
					homePageCards.forEach((p) => {
						p.RetailerId = retailerId;
						let query = store.put(p);
						query.onerror = (e) => {
							response.FailureInformation = "There was an error inserting a card..";
							return reject(response);
						};
					});
					response.Success = true;
					return resolve(response);
				};
			})
			.catch((error) => {
				//console.log("saveCart: catch triggered");
				return reject({
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

export function updateHomePageCard(homePageCard) {
	return new Promise((resolve, reject) => {
		if (homePageCard == null)
			return resolve({
				Success: true,
				FailureInformation: "",
			});
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to update home page card",
				};
				let db = responseDb.Db;
				let trans = db.transaction(["HomePageCards"], "readwrite");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("HomePageCards");
				store.onerror = (e) => {
					return reject(response);
				};

				let query = store.put(homePageCard);
				query.onsuccess = (e) => {
					console.log("cacheIndexDBService|updateHomePageCard: success!", homePageCard);
					response.Success = true;
					response.FailureInformation = "";
					return resolve(response);
				};
				query.onerror = (e) => {
					console.error("cacheIndexDBService|updateHomePageCard: query.onerror", e);
					return reject(response);
				};
			})
			.catch((error) => {
				console.error("cacheIndexDBService|updateHomePageCard: catch triggered", error);
				return reject({
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

/*Retailer Logo */
export function removeRetailer(retailerId) {
	return new Promise((resolve, reject) => {
		if (retailerId == null)
			return resolve({
				Success: true,
				FailureInformation: "",
			});
		retailerId = parseInt(retailerId);
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to remove retailer",
				};
				let db = responseDb.Db;
				let trans = db.transaction(["Retailers"], "readwrite");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("Retailers");
				store.onerror = (e) => {
					return reject(response);
				};
				let query = store.delete(retailerId);
				query.onsuccess = (e) => {
					response.Success = true;
					response.FailureInformation = "";
					return resolve(response);
				};
				query.onerror = (e) => {
					return reject(response);
				};
			})
			.catch((error) => {
				//console.log("saveCart: catch triggered");
				return reject({
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

export function getLogo(retailerId) {
	return new Promise((resolve, reject) => {
		if (retailerId == null)
			return resolve({
				Success: true,
				FailureInformation: "",
				Base64EncodedString: null,
			});
		retailerId = parseInt(retailerId);
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to get Logo from " + dbName,
					Base64EncodedString: null,
				};
				let db = responseDb.Db;
				let trans = db.transaction(["Retailers"], "readonly");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("Retailers");
				store.onerror = (e) => {
					return reject(response);
				};
				let query = store.get(retailerId);
				query.onsuccess = (e) => {
					response.Success = true;
					response.FailureInformation = "";
					response.Base64EncodedString = e.target.result != null ? e.target.result.Base64EncodedString : null;
					return resolve(response);
				};
				query.onerror = (e) => {
					return reject(response);
				};
			})
			.catch((error) => {
				return reject({
					Success: false,
					FailureInformation: error.Message,
					Base64EncodedString: null,
				});
			});
	});
}

export function getRetailerCountry(retailerId) {
	return new Promise((resolve, reject) => {
		if (retailerId == null)
			return resolve({
				Success: true,
				FailureInformation: "",
				Country: "",
			});
		retailerId = parseInt(retailerId);
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to get Retailer Country from " + dbName,
					Base64EncodedString: null,
				};
				let db = responseDb.Db;
				let trans = db.transaction(["Retailers"], "readonly");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("Retailers");
				store.onerror = (e) => {
					return reject(response);
				};
				let query = store.get(retailerId);
				query.onsuccess = (e) => {
					response.Success = true;
					response.FailureInformation = "";
					response.Country = e.target.result != null ? e.target.result.Country : null;
					return resolve(response);
				};
				query.onerror = (e) => {
					return reject(response);
				};
			})
			.catch((error) => {
				return reject({
					Success: false,
					FailureInformation: error.Message,
					Country: "",
				});
			});
	});
}

export function getRetailers() {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Retailers: [],
					Success: false,
					FailureInformation: "Unable to retreive retailers",
				};
				let db = responseDb.Db;
				let trans = db.transaction(["Retailers"], "readonly");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("Retailers");
				store.onerror = (e) => {
					return reject(response);
				};
				let query = store.getAll();
				query.onsuccess = (e) => {
					//console.log("cacheIndexDbService|getRetailers|result:", e.target.result);
					response.Retailers = e.target.result;
					response.Success = true;
					response.FailureInformation = "";
					return resolve(response);
				};
				query.onerror = (e) => {
					return reject(response);
				};
			})
			.catch((error) => {
				//console.log("saveCart: catch triggered");
				return reject({
					Retailers: [],
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

export function saveRetailerLogo(retailerId, retailerName, base64EncodedString, country, storeId, storeName) {
	return new Promise((resolve, reject) => {
		if (retailerId == null || retailerId == 0)
			return resolve({
				Success: true,
				FailureInformation: "",
			});
		retailerId = parseInt(retailerId);
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to save Retailer",
				};
				let db = responseDb.Db;
				let trans = db.transaction(["Retailers"], "readwrite");
				trans.onerror = (e) => {
					//console.log("saveCart: promise rejected - transaction");
					return reject(response);
				};
				let store = trans.objectStore("Retailers");
				store.onerror = (e) => {
					//console.log("saveCart: promise rejected - store");
					return reject(response);
				};
				let logo = {
					RetailerId: retailerId,
					RetailerName: retailerName,
					Base64EncodedString: base64EncodedString,
					Country: country ? country : "",
					StoreId: storeId ? storeId : null,
					StoreName: storeName ? storeName : "",
				};
				//console.info("cachINdexDb|saveRetailerLogo|logo:", logo);
				let query = store.put(logo);
				query.onsuccess = (e) => {
					//console.log("saveCart: promise resolved");
					response.Success = true;
					response.FailureInformation = "";
					return resolve(response);
				};
				query.onerror = (e) => {
					//console.log("saveCart: promise rejected - query");
					return reject(response);
				};
			})
			.catch((error) => {
				//console.log("saveCart: catch triggered");
				return reject({
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

export function saveRetailerStore(retailerId, storeId, storeName) {
	return new Promise((resolve, reject) => {
		if (retailerId == null)
			return resolve({
				Success: true,
				FailureInformation: "",
			});
		retailerId = parseInt(retailerId);
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to save Retailer",
				};
				let db = responseDb.Db;
				let logo = {};
				//get existing record....
				let trans = db.transaction(["Retailers"], "readonly");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("Retailers");
				store.onerror = (e) => {
					return reject(response);
				};
				let query = store.get(retailerId);
				query.onsuccess = (e) => {
					if (e.target.result == null) return reject(response);
					logo = e.target.result;
					//Update store info
					let transSave = db.transaction(["Retailers"], "readwrite");
					transSave.onerror = (e) => {
						//console.log("saveCart: promise rejected - transaction");
						return reject(response);
					};
					let storeSave = transSave.objectStore("Retailers");
					storeSave.onerror = (e) => {
						//console.log("saveCart: promise rejected - store");
						return reject(response);
					};
					logo = { ...logo, StoreId: storeId ? storeId : null, StoreName: storeName ? storeName : "" };
					//console.info("cachINdexDb|saveRetailerLogo|logo:", logo);
					let querySave = storeSave.put(logo);
					querySave.onsuccess = (e) => {
						//console.log("saveCart: promise resolved");
						response.Success = true;
						response.FailureInformation = "";
						return resolve(response);
					};
					querySave.onerror = (e) => {
						//console.log("saveCart: promise rejected - querySave");
						return reject(response);
					};
				};
			})
			.catch((error) => {
				//console.log("saveCart: catch triggered");
				return reject({
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

/*Splash Page Carousel */
export function getSplashPages(retailerId) {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to get Splash Pages from " + dbName,
					SplashPages: [],
				};
				let db = responseDb.Db;
				let trans = db.transaction(["SplashPages"], "readonly");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("SplashPages");
				store.onerror = (e) => {
					return reject(response);
				};
				let query = store.index("ByRetailerId").getAll(retailerId);
				query.onsuccess = (e) => {
					let records = [];
					if (e.target.result != null) {
						records = e.target.result.sort((a, b) => {
							return a.SortOrder - b.SortOrder;
						});
					}
					response.Success = true;
					response.FailureInformation = "";
					response.SplashPages = records;
					return resolve(response);
				};
				query.onerror = (e) => {
					return reject(response);
				};
			})
			.catch((error) => {
				return reject({
					Success: false,
					FailureInformation: error.Message,
					SplashPages: [],
				});
			});
	});
}

export function saveSplashPages(retailerId, splashPages) {
	//console.log("saveCart: making promise");
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to save Splash Pages",
				};
				let db = responseDb.Db;
				let trans = db.transaction(["SplashPages"], "readwrite");
				trans.onerror = (e) => {
					//console.log("saveCart: promise rejected - transaction");
					return reject(response);
				};
				let storeClear = trans.objectStore("SplashPages");
				var storeClearRequest = storeClear.clear();
				storeClearRequest.onsuccess = (e) => {
					let store = trans.objectStore("SplashPages");
					store.onerror = (e) => {
						//console.log("saveCart: promise rejected - store");
						return reject(response);
					};
					splashPages.forEach((p) => {
						p.RetailerId = retailerId;
						let query = store.put(p);
						query.onerror = (e) => {
							response.FailureInformation = "There was an error inserting a page..";
							return reject(response);
						};
					});
					response.Success = true;
					return resolve(response);
				};
			})
			.catch((error) => {
				//console.log("saveCart: catch triggered");
				return reject({
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

//Private functions
//---------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------
function _getDb() {
	//NOTE: If you add new tables to the DB make sure you add them to the
	//clear method!
	return new Promise((resolve, reject) => {
		let response = {
			Success: false,
			FailureInformation: "There was a problem getting the " + dbName,
			Db: null,
		};
		var request = window.indexedDB.open(dbName, dbVersion);
		request.onupgradeneeded = (e) => {
			//console.log("cartIndexDbService|_getDb|old:" + e.oldVersion + " > new:" + e.newVersion);
			let db = e.target.result;
			if (e.newVersion >= 1) {
				if (db.objectStoreNames.contains("Logos")) db.deleteObjectStore("Logos");
				let table = db.createObjectStore("Logos", { keyPath: "RetailerId" });
			}
			if (e.newVersion >= 2) {
				if (db.objectStoreNames.contains("SplashPages")) db.deleteObjectStore("SplashPages");
				let table = db.createObjectStore("SplashPages", { keyPath: "SplashPageId" });
				table.createIndex("ByRetailerId", "RetailerId");
			}
			if (e.newVersion >= 3) {
				if (db.objectStoreNames.contains("BenFamilies")) db.deleteObjectStore("BenFamilies");
				db.createObjectStore("BenFamilies", { keyPath: "Name" });

				//if (db.objectStoreNames.contains("BenColors")) db.deleteObjectStore("BenColors");
				//let table = db.createObjectStore("BenColors", { keyPath: "ColorName" });
				//table.createIndex("ByNumber", "ColorNumber");
				//table.createIndex("ByFamily", "Family");
			}
			if (e.newVersion >= 4) {
				if (db.objectStoreNames.contains("BenColors")) db.deleteObjectStore("BenColors");
				let table = db.createObjectStore("BenColors", { keyPath: "ColorName" });
				table.createIndex("ByNumber", "ColorNumber");
				table.createIndex("ByFamily", "Family");
				table.createIndex("ByExteriorAvailability", "ExteriorAvailability");
			}
			if (e.newVersion >= 5) {
				if (db.objectStoreNames.contains("SearchOptionBrands")) db.deleteObjectStore("SearchOptionBrands");
				let tableBrands = db.createObjectStore("SearchOptionBrands", { keyPath: "Id" });
				tableBrands.createIndex("ByDisplay", "Display");

				if (db.objectStoreNames.contains("SearchOptionClasses")) db.deleteObjectStore("SearchOptionClasses");
				let tableClasses = db.createObjectStore("SearchOptionClasses", { keyPath: "Id" });
				tableClasses.createIndex("ByDisplay", "Display");

				if (db.objectStoreNames.contains("SearchOptionDepartments")) db.deleteObjectStore("SearchOptionDepartments");
				let tableDepartments = db.createObjectStore("SearchOptionDepartments", { keyPath: "Id" });
				tableDepartments.createIndex("ByDisplay", "Display");

				if (db.objectStoreNames.contains("SearchOptionLines")) db.deleteObjectStore("SearchOptionLines");
				let tableLines = db.createObjectStore("SearchOptionLines", { keyPath: "Id" });
				tableLines.createIndex("ByDisplay", "Display");
			}
			if (e.newVersion >= 6) {
				if (db.objectStoreNames.contains("HomePageCards")) db.deleteObjectStore("HomePageCards");
				let table = db.createObjectStore("HomePageCards", { keyPath: "HomePageCardId" });
				table.createIndex("ByRetailerId", "RetailerId");
			}
			if (e.newVersion >= 7) {
				if (db.objectStoreNames.contains("PaintColors")) db.deleteObjectStore("PaintColors");
				let pcTable = db.createObjectStore("PaintColors", { keyPath: "ColorName" });
				pcTable.createIndex("ByManufacturerId", "ManufacturerId");
				pcTable.createIndex("ByNumber", "ColorNumber");
				pcTable.createIndex("ByFamily", "Family");
				pcTable.createIndex("ByExteriorAvailability", "ExteriorAvailability");

				if (db.objectStoreNames.contains("PaintFamilies")) db.deleteObjectStore("PaintFamilies");
				let pfTable = db.createObjectStore("PaintFamilies", { keyPath: "Name" });
				pfTable.createIndex("ByManufacturerId", "ManufacturerId");
			}
			if (e.newVersion >= 8) {
				if (db.objectStoreNames.contains("BenColors")) db.deleteObjectStore("BenColors");
				if (db.objectStoreNames.contains("BenFamilies")) db.deleteObjectStore("BenFamilies");
			}
			if (e.newVersion >= 9) {
				if (db.objectStoreNames.contains("PaintColors")) db.deleteObjectStore("PaintColors");
				let pcTable = db.createObjectStore("PaintColors", { keyPath: "ColorId" });
				pcTable.createIndex("ByName", "ColorName");
				pcTable.createIndex("ByManufacturerId", "ManufacturerId");
				pcTable.createIndex("ByNumber", "ColorNumber");
				pcTable.createIndex("ByFamily", "Family");
				pcTable.createIndex("ByExteriorAvailability", "ExteriorAvailability");
			}
			if (e.newVersion >= 10) {
				//{RetailerId, Name, Base64EncodedString}
				if (db.objectStoreNames.contains("Logos")) db.deleteObjectStore("Logos");
				if (db.objectStoreNames.contains("Retailers")) db.deleteObjectStore("Retailers");
				let pcTable = db.createObjectStore("Retailers", { keyPath: "RetailerId" });
				pcTable.createIndex("ByName", "Name");
			}
		};
		request.onsuccess = (e) => {
			//console.log("Success getting Db " + dbName);
			response.Success = true;
			response.FailureInformation = "";
			response.Db = e.target.result;
			return resolve(response);
		};

		request.onerror = (e) => {
			//console.log("Error getting Db " + dbName + ": ", e);
			return reject(response);
		};
	});
}

function _getSearchOptionTableByDisplay(table, db) {
	return new Promise((resolve, reject) => {
		let response = {
			Success: false,
			FailureInformation: "Unable to get " + table,
			Records: [],
		};
		let trans = db.transaction([table], "readonly");
		trans.onerror = (e) => {
			return reject(response);
		};
		let store = trans.objectStore(table);
		store.onerror = (e) => {
			return reject(response);
		};
		let query = store.index("ByDisplay").getAll();
		query.onsuccess = (e) => {
			//console.log("getBenFamilies: promise resolved");
			response.Success = true;
			response.FailureInformation = "";
			response.Records = e.target.result != null ? e.target.result : [];
			return resolve(response);
		};
		query.onerror = (e) => {
			return reject(response);
		};
	});
}

function _saveSearchOptionTable(records, table, db) {
	return new Promise((resolve, reject) => {
		let response = {
			Success: false,
			FailureInformation: "Unable to save " + table,
		};
		let trans = db.transaction([table], "readwrite");
		trans.onerror = (e) => {
			return reject(response);
		};
		let storeClear = trans.objectStore(table);
		var storeClearRequest = storeClear.clear();
		storeClearRequest.onsuccess = (e) => {
			let store = trans.objectStore(table);
			store.onerror = (e) => {
				return reject(response);
			};
			records.forEach((p) => {
				let query = store.put(p);
				query.onerror = (e) => {
					response.FailureInformation = "There was an error inserting a " + table + " option..";
					return reject(response);
				};
			});
			response.Success = true;
			return resolve(response);
		};
	});
}
