<template>
	<transition name="fade">
		<div class="app-toast" v-if="toaster" v-bind:class="css">
			<div @click="close" class="close">x</div>
			<span v-html="text"></span>
		</div>
	</transition>
</template>

<script>
import * as _utility from "@/utility";
import { mapState } from "vuex";
export default {
	name: "cToast",
	components: {},
	data: () => ({
		toaster: false,
		color: "info",
		mode: "",
		timeout: 6000,
		text: "",
		toastPosition: "top",
	}),
	methods: {
		close() {
			this.toaster = false;
		},
		autoClose(delay) {
			if (delay == null) delay = 6000;
			const that = this;
			setTimeout(function() {
				that.toaster = false;
			}, delay);
		},
	},
	computed: {
		isMobleDevice: function() {
			return window.innerWidth < 800;
		},
		css: function() {
			let css = this.toastPosition;
			if (this.color === "info") css += " info";
			if (this.color === "success") css += " success";
			if (this.color === "warning") css += " warning";
			if (this.color === "danger") css += " danger";
			return css;
		},
	},
	watch: {},
	created() {},
	mounted() {
		this.$store.watch(
			(state, getters) => getters.systemMessage,
			(newValue, oldValue) => {
				//console.log(`Updating from ${oldValue} to ${newValue}`);
				if (oldValue) this.toaster = false;
				this.text = newValue.text;
				this.color = newValue.color;
				this.timeout = newValue.timeout;
				this.toaster = true;
				this.toastPosition = this.isMobleDevice ? "bottom" : newValue.position;

				this.autoClose(this.timeout);
			}
		);
	},
};
</script>

<style></style>
