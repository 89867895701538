<template>
	<transition name="fade">
		<div v-if="!policyAccepted" class="app-policy-notice">
			<font-awesome-icon icon="times" class="pull-right" @click="acceptPolicy" />
			<p>{{ $t("PrivacyCookies") }}</p>
			<p>
				<router-link to="/privacypolicy">{{ $t("Learn more") }}</router-link>
			</p>
			<button class="btn" @click="acceptPolicy">{{ $t("Got it!") }}</button>
		</div>
	</transition>
</template>

<script>
import * as _config from "@/config";

export default {
	name: "cPolicyNotice",
	components: {},
	props: [],
	data: () => ({
		policyAccepted: true,
	}),
	methods: {
		acceptPolicy() {
			this.policyAccepted = true;
			localStorage.setItem(_config.storagePolicyKey, true);
		},
		checkPolicyStatus() {
			let ls = localStorage.getItem(_config.storagePolicyKey);
			this.policyAccepted = !ls ? false : true;
		},
	},
	computed: {},
	watch: {},
	mounted: function() {
		this.checkPolicyStatus();
	},
};
</script>

<style></style>
