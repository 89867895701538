export default [
	{
		path: "/contractorlogo",
		name: "contractorlogo",
		component: () => import(/* webpackChunkName: "contractor" */ "@/views/contractorAdmin/vLogo.vue"),
		meta: { title: "Logo", auth: true, roles: ["retailerAdmin"] },
	},
	{
		path: "/contractorbannerads",
		name: "contractorbannerads",
		component: () => import(/* webpackChunkName: "contractor" */ "@/views/contractorAdmin/vBannerAds.vue"),
		meta: { title: "Banner Ads", auth: true, roles: ["retailerAdmin"] },
	},
	{
		path: "/contractorsplashpage",
		name: "contractorsplashpage",
		component: () => import(/* webpackChunkName: "contractor" */ "@/views/contractorAdmin/vSplashPage.vue"),
		meta: { title: "Manage Splash Pages", auth: true, roles: ["retailerAdmin"] },
	},
	{
		path: "/contractorhomepage",
		name: "contractorhomepage",
		component: () => import(/* webpackChunkName: "contractor" */ "@/views/contractorAdmin/vHomePageCard.vue"),
		meta: { title: "Manage Home Page Cards", auth: true, roles: ["retailerAdmin"] },
	},
	{
		path: "/helpedit/:id?",
		name: "helpedit",
		component: () => import(/* webpackChunkName: "contractor" */ "@/views/contractorAdmin/vHelpEdit.vue"),
		meta: { title: "Help Edit", auth: true, roles: ["globalAdmin"] },
	},
	{
		path: "/helpedit/:name?",
		name: "helpedit",
		component: () => import(/* webpackChunkName: "contractor" */ "@/views/contractorAdmin/vHelpEdit.vue"),
		meta: { title: "Help Edit", auth: true, roles: ["globalAdmin"] },
	},
	{
		path: "/comboedit/:id/:products?",
		name: "comboedit",
		component: () => import(/* webpackChunkName: "contractor" */ "@/views/combine/vProductComboEdit.vue"),
		meta: { title: "Combine Edit", auth: false, roles: ["globalAdmin"] },
	},
	{
		path: "/combonew/",
		name: "combonew",
		component: () => import(/* webpackChunkName: "contractor" */ "@/views/combine/vProductComboNew.vue"),
		meta: { title: "New Combined Products", auth: false, roles: ["globalAdmin"] },
	},
	{
		path: "/combolist/",
		name: "combolist",
		component: () => import(/* webpackChunkName: "contractor" */ "@/views/combine/vProductComboList.vue"),
		meta: { title: "Combined Products", auth: false, roles: ["globalAdmin"] },
	},
];
