import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import store from "@/store";
import * as _config from "@/config";

import publicRoutes from "@/router/publicRoutes";
import userRoutes from "@/router/userRoutes";
import accountRoutes from "@/router/accountRoutes";
import contractorRoutes from "@/router/contractorRoutes";
import contractorAdminRoutes from "@/router/contractorAdminRoutes";
import checkoutRoutes from "@/router/checkoutRoutes";

Vue.use(VueRouter);

const baseRoutes = [
	//{
	//	path: "/pptest",
	//	name: "pptest",
	//	component: () => import(/* webpackChunkName: "test" */ "@/views/vPayPalTest.vue"),
	//	meta: { title: "PayPal Testing", auth: false, roles: null },
	//},
	{
		path: "/itest",
		name: "itest",
		component: () => import(/* webpackChunkName: "test" */ "@/views/vImageTest.vue"),
		meta: { title: "Image Testing", auth: false, roles: null },
	},
	{
		path: "/notauthorized",
		name: "notauthorized",
		component: () => import(/* webpackChunkName: "misc" */ "@/views/public/vNotAuthorized.vue"),
		meta: { title: "Not Authorized", auth: false, roles: null },
	},
	{
		path: "/",
		name: "noretailer",
		component: () => import(/* webpackChunkName: "misc" */ "@/views/public/vNoRetailer.vue"),
		meta: { title: null, auth: false, roles: null },
	},
	{
		path: "*",
		name: "catchall",
		component: () => import(/* webpackChunkName: "misc" */ "@/views/public/vNotFound.vue"),
		meta: { title: "Not Found", auth: false, roles: null },
	},
];

const routes = baseRoutes.concat(publicRoutes).concat(userRoutes).concat(accountRoutes).concat(contractorRoutes).concat(contractorAdminRoutes).concat(checkoutRoutes);

let router = new VueRouter({
	mode: "history",
	//base: process.env.BASE_URL,
	routes,
});

let reRoutePath = null;
router.beforeEach((to, from, next) => {
	//If the Route auth meta data is true this url requires authentication
	if (to.meta.auth) {
		//Check to see if the user is already logged in
		store.dispatch("isUserLoggedIn").then((isLoggedIn) => {
			//If they are not logged in then save the requested URL then redirect to Login
			if (!isLoggedIn) {
				reRoutePath = to.path.substring(0, to.path.indexOf("%"));
				return next("/login");
			}
			//If they are logged in, but have insufficient privileges redirect to home screen
			if (to.meta.roles != null) {
				let canView = false;
				//valid role strings are: 'contractor', 'primary', 'retailerAdmin', 'allowedPayments'
				to.meta.roles.forEach((r) => {
					if (store.getters.isAllowedPayments && r === "allowedPayments") canView = true;
					if (store.getters.isContractor && r === "contractor") canView = true;
					if (store.getters.isPrimary && r === "primary") canView = true;
					if (store.getters.isRetailerAdmin && r === "retailerAdmin") canView = true;
					if (store.getters.isGlobalAdmin && r === "globalAdmin") canView = true;
				});
				if (!canView) return next("/notauthorized");
			}
			//If they are logged in, and there is a re-route, reset the variable and take them there
			if (reRoutePath != null) {
				const path = reRoutePath;
				reRoutePath = null;
				return next(path);
			}
			//Finally, if we got here just take them to the requested URL
			return next();
		});
	} else {
		//The requested URL does not require authentication
		//As long as we are not going to the login page we need to look for a re-route
		if (to.path != "/logout" && to.path != "/login" && to.path != "/signup" && reRoutePath != null) {
			const path = reRoutePath;
			reRoutePath = null;
			return next(path);
		}
		//Finally, if we got here the page does not require authentication and there was no re-route
		return next();
	}
});

//This ensures that the side menu and cart are
//collapsed when the user navigates..
//It also set the Browser title based on the
//Route title meta data
router.afterEach((to, from) => {
	//store.commit("showMenu", false);
	//store.commit("showCart", false);
	let el = document.getElementById("side-drawer");
	if (el) el.classList.remove("open");
	Vue.nextTick(() => {
		document.title = to.meta.title || _config.appTitle;
		document.getElementById("main-content").scrollTo(0, 0);
	});
});

export default router;
