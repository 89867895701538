<template>
  <div class="panel panel-default">
    <div class="panel-heading">
      <h4 class="panel-title" @click="isCollapsed = !isCollapsed">
        <a>
          <span v-if="items.children.length > 0 && isCollapsed" class="badge pull-right"><font-awesome-icon icon="plus" /></span>
          <span v-if="items.children.length > 0 && !isCollapsed" class="badge pull-right"><font-awesome-icon icon="minus" /></span>
          <router-link :to="getLink(items.topLevel)">{{ $t(items.topLevel.name) }}</router-link>
        </a>
      </h4>
    </div>
    <div class="panel-collapse" :class="{ collapse: isCollapsed }">
      <div class="panel-body">
        <ul>
          <li v-for="(c, index) in items.children" :key="c.name + index">
            <div class="child-title" v-if="c.routeName === null">{{ $t(c.name) }}</div>
            <router-link v-else :to="getLink(c)">{{ $t(c.name) }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cAccordianRouterLink",
  components: {},
  props: ["items", "initialiaze"],
  data: () => ({
    isCollapsed: false,
  }),
  methods: {
    getLink(item) {
      if (item.routeName === null) return "";
      if (item.url && item.url.length > 0) return item.url;
      let name = item.routeName;
      let id = item.parameterId;
      let value = item.parameter;
      if (item.parameterId != null) {
        return { name: name, params: { id: value } };
      } else return { name: name };
    },
  },
  computed: {},
  watch: {
    initialiaze: function (newValue) {
      this.isCollapsed = newValue === "collapsed";
    },
  },
  mounted: function () {
    this.isCollapsed = this.initialiaze === "collapsed";
  },
};
</script>

<style>
.child-title {
  color: #696763;
  text-decoration: none;
  cursor: default;
  margin-right: 10px;
  border-bottom: 1px solid #696763;
}
</style>
