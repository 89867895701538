<template>
  <transition name="fade">
    <div class="app-filter" v-if="showAppFilter">
      <div style="height:25px;">
        <font-awesome-icon icon="times" @click="close" class="pull-right" />
      </div>
      <!--department -->
      <div class="box">
        <cHomeFilterBoxContainer
          :title="$t('Department')"
          v-model="value.Departments"
          v-on:submit="submit"
        />
      </div>
      <!--classes -->
      <div class="box">
        <cHomeFilterBoxContainer
          :title="$t('Class')"
          v-model="value.Classes"
          v-on:submit="submit"
        />
      </div>
      <!--line -->
      <div class="box">
        <cHomeFilterBoxContainer :title="$t('Line')" v-model="value.Lines" v-on:submit="submit" />
      </div>
      <!--brands -->
      <div class="box">
        <cHomeFilterBoxContainer
          :title="$t('Brands')"
          v-model="value.Brands"
          v-on:submit="submit"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import cHomeFilterBoxContainer from "@/components/filters/cHomeFilterBoxContainer";

export default {
  name: "cAppFilter",
  components: { cHomeFilterBoxContainer },
  props: ["value", "showAppFilter"],
  data: () => ({}),
  methods: {
    submit() {
      this.$emit("closefilter");
      this.$emit("submit");
    },
    close() {
      this.$emit("closefilter");
      //this.showAppFilter = false;
    },
  },
  computed: {},
  watch: {},
  mounted: function() {},
};
</script>

<style></style>
