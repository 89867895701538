<template>
  <div class="app-content">
    <cHome></cHome>
  </div>
</template>

<script>
import cHome from "@/components/public/cHome";

export default {
  name: "vRetailer",
  components: { cHome },
};
</script>

<style></style>
