import * as _utility from "@/utility";

//These can be accessed throughout the app as:
//this.$store.state.payment.orderInfo;
//this.$store.state.payment.paymentMethod;
//this.$store.state.payment.paymentObject;
//this.$store.state.payment.paymentResponse;
//this.$store.state.payment.shippingInfo;

//https://vuex.vuejs.org/guide/modules.html
const defaultShippingInfo = {
	selectedPickupMethod: "pickup",
	selectedAddress: {
		Address: "",
		City: "",
		CompanyName: "",
		ContractorJobId: null,
		FirstName: "",
		JobName: "",
		LastName: "",
		State: "",
		Zip: "",
		Phone: "",
	},
	isNewAddress: false,
	pickupDeliveryTime: null,
	pickupDeliveryDisplay: null,
	textNotification: "",
	sendEmailUpdates: true,
	finePrint: "",
	deliveryNote: "",
};
const defaultOrderInfo = {
	PoNumber: "",
	Date: null,
	ReferenceNumber: "",
	JobName: "",
	SoldTo: {
		CompanyName: "",
		FirstName: "",
		LastName: "",
		Address: "",
		City: "",
		State: "",
		Zip: "",
	},
	Email: "",
	FreightAmount: 0,
	FreightLabel: "",
	ProvinceTaxAmount: 0,
	SalesTaxAmount: 0,
	TotalAmount: 0,
	Currency: "",
	LineItems: [],
};
const defaultPaymentMethod = {
	TypeOfPayment: null, //onaccount, newcard, cardonfile
	SaveCardInfo: false,
	PaymentAccountID: null,
};
const defaultPaymentObject = {
	PreLogId: null,
	IsContractorPayment: false,
	Invoices: [], //{Iv_reference:"",cj_id:<guid>, PaymentMade: 0, DiscountGiven:0}
	ReferenceNumber: null,
	TotalAmount: 0,
};
const defaultPaymentResponse = {
	ApprovalNumber: "",
	ApprovedAmount: "",
	CardLogo: "",
	ExpressResponseMessage: "",
	LastFour: "",
	TransactionID: "",
	TranDT: "",
	PayPalResponse: "",
};

const modulePayment = {
	state: () => ({
		shippingInfo: { ...defaultShippingInfo },
		orderInfo: { ...defaultOrderInfo },
		paymentObject: { ...defaultPaymentObject },
		paymentMethod: { ...defaultPaymentMethod },
		paymentResponse: { ...defaultPaymentResponse },
	}),
	mutations: {
		clearPayment(state, isContractorPayment) {
			//console.log("paymentStore|clearPayment fired!");
			state.shippingInfo = { ...defaultShippingInfo };
			state.orderInfo = { ...defaultOrderInfo };
			state.paymentObject = { ...defaultPaymentObject, IsContractorPayment: isContractorPayment };
			state.paymentMethod = { ...defaultPaymentMethod };
			state.paymentResponse = { ...defaultPaymentResponse };
		},
		clearShippingInfo(state) {
			state.shippingInfo = { ...defaultShippingInfo };
		},
		clearShippingInfoSelectedAddress(state) {
			state.shippingInfo.selectedAddress = { ...defaultShippingInfo.selectedAddress };
		},
		clearOrderInfo(state) {
			state.orderInfo = { ...defaultOrderInfo };
		},
		setOrderInfoObject(state, value) {
			state.orderInfo = value;
		},
		setPaymentObject(state, value) {
			state.paymentObject = value;
		},
		setShippingInfo(state, value) {
			state.shippingInfo = value;
		},
	},
	actions: {},
	getters: {
		shippingInfo: (state) => state.shippingInfo,
		orderInfo: (state) => state.orderInfo,
		paymentObject: (state) => state.paymentObject,
		paymentMethod: (state) => state.paymentMethod,
		paymentResponse: (state) => state.paymentResponse,
	},
};

export default modulePayment;
