import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utility/filters";

//--------------------------------------------------------------------
//Styling framework...
//--------------------------------------------------------------------
import { BootstrapVue } from "bootstrap-vue";
// Install BootstrapVue
Vue.use(BootstrapVue);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

//--------------------------------------------------------------------
//Site styles
//--------------------------------------------------------------------
import "@/style/bootstrap.override.css";
//import "@/style/shopping.css";
//import "@/style/bmore.css";
import "@/style/bmorev2.css";
import "@/style/print.css";

//--------------------------------------------------------------------
//Support for image cropping
//--------------------------------------------------------------------
import "cropperjs/dist/cropper.min.css";

//--------------------------------------------------------------------
//Font library
//--------------------------------------------------------------------
import { library } from "@fortawesome/fontawesome-svg-core";
//https://fontawesome.com/cheatsheet
import {
	faAngleUp,
	faAngleDown,
	faEdit,
	faSave,
	faPlus,
	faTrash,
	faUpload,
	faBars,
	faShoppingCart,
	faUserLock,
	faArrowCircleLeft,
	faArrowCircleRight,
	faUser,
	faMinus,
	faTag,
	faFilePdf,
	faFileExcel,
	faReply,
	faCheck,
	faDownload,
	faTimes,
	faPlusCircle,
	faMinusCircle,
	faLockOpen,
	faLock,
	faSearch,
	faArrowRight,
	faFilter,
	faTimesCircle,
	faBold,
	faItalic,
	faUnderline,
	faAlignCenter,
	faAlignLeft,
	faAlignRight,
	faList,
	faListOl,
	faArrowCircleDown,
	faArrowCircleUp,
	faHeart,
	faHeartBroken,
	faExternalLinkAlt,
	faLocationArrow,
	faSignOutAlt,
	faQuestionCircle,
	faSortAlphaUpAlt,
	faSortAlphaDown,
	faSortAlphaUp,
	faSortNumericDown,
	faSortNumericUp,
	faSortNumericDownAlt,
	faSortNumericUpAlt,
	faSortAlphaDownAlt,
	faSearchPlus,
	faSearchMinus,
	faLocationDot,
	faCaretDown,
	faMapLocation,
	faMapLocationDot,
	faFileImport,
} from "@fortawesome/free-solid-svg-icons";
library.add(
	faAngleUp,
	faAngleDown,
	faEdit,
	faSave,
	faPlus,
	faMinus,
	faTrash,
	faUpload,
	faBars,
	faShoppingCart,
	faUserLock,
	faUser,
	faArrowCircleLeft,
	faArrowCircleRight,
	faTag,
	faFilePdf,
	faFileExcel,
	faReply,
	faCheck,
	faDownload,
	faTimes,
	faPlusCircle,
	faMinusCircle,
	faLockOpen,
	faLock,
	faSearch,
	faSearchPlus,
	faArrowRight,
	faFilter,
	faTimesCircle,
	faBold,
	faItalic,
	faUnderline,
	faAlignCenter,
	faAlignLeft,
	faAlignRight,
	faList,
	faListOl,
	faArrowCircleDown,
	faArrowCircleUp,
	faHeart,
	faHeartBroken,
	faExternalLinkAlt,
	faLocationArrow,
	faSignOutAlt,
	faQuestionCircle,
	faSortAlphaDown,
	faSortAlphaDownAlt,
	faSortAlphaUp,
	faSortAlphaUpAlt,
	faSortNumericDown,
	faSortNumericUp,
	faSortNumericDownAlt,
	faSortNumericUpAlt,
	faSearchMinus,
	faLocationDot,
	faCaretDown,
	faMapLocationDot,
	faFileImport
);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import i18n from "./i18n";

Vue.prototype.$dayjs = dayjs();
//Vue.prototype.$dayjs.extend(localizedFormat);

Vue.prototype.$emptyGuid = "00000000-0000-0000-0000-000000000000";
Vue.prototype.$runningLocal = location.port.length > 0;

Vue.prototype.$version = require("../package.json").version || 0;

Vue.config.productionTip = false;
export const eventBus = new Vue();
new Vue({
	router,
	store,
	i18n,
	render: function (h) {
		return h(App);
	},
}).$mount("#app");
