<template>
	<div v-if="currentPage != null">
		<transition name="fade">
			<cSplashElement v-bind:element="currentPage" />
		</transition>
	</div>
</template>

<script>
//-----------------------------------------------------------------
//-----------------------------------------------------------------
//-----------------------------------------------------------------
//If we want to make it "slide" we can look at this...
//https://vuejs.org/v2/guide/transitions.html#Dynamic-Transitions
//-----------------------------------------------------------------
//-----------------------------------------------------------------
//-----------------------------------------------------------------

import cSplashElement from "@/components/shared/cSplashElement";

export default {
	name: "cSplashCarousel",
	components: { cSplashElement },
	props: ["pages"],
	data: () => ({
		currentPage: null,
		timer: null,
		delay: 5000,
	}),
	methods: {
		load() {
			//console.log("cSplashCarousel|load fired!");
			//this.pages.forEach((p) => {
			//	console.log(p.SplashPageId + ": " + p.Pos);
			//});
			if (this.pages.length > 0) this.currentPage = this.pages[0];
			const that = this;
			if (this.timer != null) clearInterval(this.timer);
			if (this.pages.length > 0) {
				this.timer = setInterval(() => {
					if (that.currentPage.Pos >= that.pages.length) {
						that.currentPage = null;
						that.currentPage = that.pages[0];
					} else that.currentPage = that.pages[that.currentPage.Pos];
				}, that.delay);
			}
		},
	},
	computed: {},
	watch: {},
	mounted: function() {
		this.load();
	},
};
</script>
