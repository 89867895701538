<template>
  <div class="splash-container" :class="containerClass" :id="'container-' + element.elementId">
    <img :src="element.ImageBase64String" />
    <div v-show="element.BillboardHtml.length > 0" class="splash-bilboard" :id="'bilboard-' + element.elementId" v-bind:style="bilboardStyle" v-html="element.BillboardHtml"></div>
  </div>
</template>

<script>
export default {
  name: "cSplashElement",
  components: {},
  props: ["element"],
  data: () => ({}),
  computed: {
    containerClass: function () {
      return {
        "d-flex": this.element.BillboardLeft == "",
        "align-items-center": this.element.BillboardLeft == "",
        canedit: this.element.canEdit == true,
      };
    },
    bilboardStyle: function () {
      return {
        backgroundColor: this.element.BillboardBackgroundColor,
        "margin-left": this.element.BillboardLeft !== "" ? "auto" : "calc((100vw - 350px) / 2)",
        left: this.element.BillboardLeft !== "" ? this.element.BillboardLeft : "auto",
        top: this.element.BillboardTop !== "" ? this.element.BillboardTop : "auto",
      };
    },
  },
  mounted: function () {},
};
</script>

<style>
.splash-container {
  width: 100%;
  min-height: 300px;
  position: relative;
  margin-bottom: 25px;
  text-align: center;
}
.splash-container img {
  max-height: 300px;
}
.splash-bilboard {
  position: absolute;
  width: 350px;
  margin-left: calc((100vw - 350px) / 2);
  padding: 20px;
}
.canedit .splash-bilboard {
  cursor: pointer;
}

.ql-align-center,
p.ql-align-center {
  text-align: center;
}
.ql-align-right,
p.ql-align-right {
  text-align: right;
}
.ql-align-left,
p.ql-align-left {
  text-align: left;
}

/*Small screen support*/
@media screen and (max-width: 576px) {
  .splash-container {
    min-height: auto;
    height: 100px;
  }
  .splash-container img {
    max-width: 100%;
    max-height: 100px;
  }
  /* .splash-bilboard {
    margin-left: 0px !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
  }
  .splash-container {
    height: auto;
    min-height: 100px;
  } */
}
@media screen and (min-width: 577px) and (max-width: 1020px) {
  .splash-container {
    min-height: auto;
    height: 200px;
  }
}
@media screen and (max-width: 1020px) {
  .splash-bilboard {
    display: none;
  }
  .splash-container img {
    max-width: 100%;
    max-height: 200px;
  }
}
</style>
