import Vue from "vue";
import VueI18n from "vue-i18n";
//https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/
Vue.use(VueI18n);

function loadLocaleMessages() {
	const locales = require.context("./assets/locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
	const messages = {};
	//console.info("loadLocaleMessages", locales);
	locales.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});
	return messages;
	/* 	let en = null;
	let es = null;
	if (location.port == "8080") {
		en = require("../public/assets/locales/en.json");
		es = require("../public/assets/locales/es.json");
	} else {
		en = require("./assets/locales/en.json");
		es = require("./assets/locales/es.json");
	}
	return { en: en, es: es }; */
}

export default new VueI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || "en",
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
	messages: loadLocaleMessages(),
});
