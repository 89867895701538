import * as _config from "@/config";
import * as _utility from "@/utility";

const dbName = _config.cartDb;
const dbVersion = 2;

export function addToCart(product, storeId) {
	//console.log("saveCart: making promise");
	return new Promise((resolve, reject) => {
		_getNextLineNumber(product.LineNumber).then((lineNumber) => {
			_getDb()
				.then((responseDb) => {
					let response = {
						Success: false,
						FailureInformation: "Unable to save Cart",
						CartId: null,
						UnitPrice: null,
						LineNumber: 0,
					};
					let db = responseDb.Db;
					let trans = db.transaction(["Cart"], "readwrite");
					trans.onerror = (e) => {
						//console.log("saveCart: promise rejected - transaction");
						return reject(response);
					};
					let store = trans.objectStore("Cart");
					store.onerror = (e) => {
						//console.log("saveCart: promise rejected - store");
						return reject(response);
					};
					if (product.CartId == null || product.CartId === _utility.emptyGuid) {
						product.CartId = _utility.newGuid();
					}
					product.LineNumber = lineNumber;
					product.StoreId = storeId;
					let query = store.put(product);
					query.onsuccess = (e) => {
						//console.log("saveCart: promise resolved");
						response.Success = true;
						response.FailureInformation = "";
						response.CartId = product.CartId;
						response.UnitPrice = product.UnitPrice;
						response.LineNumber = product.LineNumber;
						return resolve(response);
					};
					query.onerror = (e) => {
						//console.log("saveCart: promise rejected - query");
						return reject(response);
					};
				})
				.catch((error) => {
					//console.log("saveCart: catch triggered");
					return reject({
						Success: false,
						FailureInformation: error.Message,
						CartId: null,
					});
				});
		});
	});
}

export function emptyCart(storeId) {
	return new Promise((resolve, reject) => {
		getCart(0, true).then((cartResponse) => {
			_getDb().then((responseDb) => {
				let db = responseDb.Db;
				// let cart = [];
				// cartResponse.target.result.forEach((p) => {
				// 	if (p.StoreId !== storeId) cart.push(p);
				// });
				let transClear = db.transaction(["Cart"], "readwrite");
				transClear.objectStore("Cart").clear();
				transClear.oncomplete = (e) => {
					//console.info("rebuilding cart...");
					cartResponse.Cart.forEach((product) => {
						if (product.StoreId !== storeId) {
							let db = responseDb.Db;
							let trans = db.transaction(["Cart"], "readwrite");
							let store = trans.objectStore("Cart");
							let query = store.put(product);
						}
					});
					return resolve({ Success: true, FailureInformation: "" });
				};
			});
		});
	});
}

export function isProductInCart(productId, storeId) {
	//console.log("productIsInCart: making promise");
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let responseProduct = {
					IsProductInCart: false,
					Success: false,
					FailureInformation: "Unable to look for ProductId in cart",
				};
				let db = responseDb.Db;
				let trans = db.transaction(["Cart"], "readonly");
				trans.onerror = (e) => {
					//console.log("productIsInCart: promise rejected - transaction");
					return reject(responseProduct);
				};
				let store = trans.objectStore("Cart");
				store.onerror = (e) => {
					//console.log("productIsInCart: promise rejected - store");
					return reject(responseProduct);
				};

				let query = store.index("ByProductId").getAll(productId);
				query.onsuccess = (e) => {
					//console.log("productIsInCart: promise resolved - query");
					//console.log(e.target.result);
					//responseProduct.IsProductInCart = e.target.result != null;
					let inCart = false;
					e.target.result.forEach((p) => {
						if (p.StoreId === storeId) inCart = true;
					});
					responseProduct.IsProductInCart = inCart;
					responseProduct.Success = true;
					responseProduct.FailureInformation = "";
					return resolve(responseProduct);
				};
				query.onerror = (e) => {
					console.log("productIsInCart: promise rejected - query");
					return reject(responseProduct);
				};
			})
			.catch((error) => {
				console.log("productIsInCart: catch triggered");
				return reject({
					IsProductInCart: false,
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

export function removeFromCart(cartId) {
	//console.log("deleteCart: making promise");
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let responseCart = {
					Success: false,
					FailureInformation: "Unable to remove item from Cart",
				};
				let db = responseDb.Db;
				let trans = db.transaction(["Cart"], "readwrite");
				trans.onerror = (e) => {
					//console.log("deleteCart: promise rejected - transaction");
					return reject(responseCart);
				};
				let store = trans.objectStore("Cart");
				store.onerror = (e) => {
					//console.log("deleteCart: promise rejected - store");
					return reject(responseCart);
				};

				let query = store.delete(cartId);
				query.onsuccess = (e) => {
					//console.log("deleteCart: promise resolved");
					responseCart.Success = true;
					responseCart.FailureInformation = "";
					return resolve(responseCart);
				};
				query.onerror = (e) => {
					//console.log("deleteCart: promise rejected - query");
					return reject(responseCart);
				};
			})
			.catch((error) => {
				//console.log("deleteCart: catch triggered");
				return reject({
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

export function getCart(storeId, getAll = false) {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to get Cart from " + dbName,
					Cart: [],
				};
				let db = responseDb.Db;
				let trans = db.transaction(["Cart"], "readonly");
				trans.onerror = (e) => {
					return reject(response);
				};
				let store = trans.objectStore("Cart");
				store.onerror = (e) => {
					return reject(response);
				};
				let query = store.index("ByLineNumber").getAll();
				query.onsuccess = (e) => {
					let cart = [];
					e.target.result.forEach((p) => {
						if (getAll || p.StoreId === storeId) cart.push(p);
					});
					response.Success = true;
					response.FailureInformation = "";
					//response.Cart = e.target.result;
					response.Cart = cart;
					return resolve(response);
				};
				query.onerror = (e) => {
					return reject(response);
				};
			})
			.catch((error) => {
				return reject({
					Success: false,
					FailureInformation: error.Message,
					Cart: [],
				});
			});
	});
}

export function updateItemLocation(cartId, newValue) {
	return new Promise((resolve, reject) => {
		_getDb()
			.then((responseDb) => {
				let response = {
					Success: false,
					FailureInformation: "Unable to get Cart from " + dbName,
				};
				let db = responseDb.Db;
				let trans = db.transaction(["Cart"], "readwrite");
				trans.onerror = (e) => {
					//console.log("updateItemLocation: promise rejected - transaction");
					return reject(response);
				};
				let store = trans.objectStore("Cart");
				store.onerror = (e) => {
					//console.log("updateItemLocation: promise rejected - store");
					return reject(response);
				};
				let query = store.get(cartId);
				query.onsuccess = (e) => {
					//console.log("updateItemLocation: promise resolved", e);
					//we have the entire cart record, find and update the Location prompt;
					let cartItem = e.target.result;
					cartItem.Prompts.forEach((p) => {
						if (p.Label === "Location") p.Value = newValue;
					});
					store.put(cartItem);
					response.Success = true;
					response.FailureInformation = "";
					return resolve(response);
				};
				query.onerror = (e) => {
					//console.log("updateItemLocation: promise rejected - request");
					return reject(response);
				};
			})
			.catch((error) => {
				//console.log("updateItemLocation: catch triggered");
				return reject({
					Success: false,
					FailureInformation: error.Message,
				});
			});
	});
}

//Private functions
//---------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------
function _getDb() {
	return new Promise((resolve, reject) => {
		let response = {
			Success: false,
			FailureInformation: "There was a problem getting the " + dbName,
			Db: null,
		};
		var request = window.indexedDB.open(dbName, dbVersion);
		request.onupgradeneeded = (e) => {
			//console.log("cartIndexDbService|_getDb|old:" + e.oldVersion + " > new:" + e.newVersion);
			let db = e.target.result;
			if (e.newVersion == 1) {
				if (db.objectStoreNames.contains("Cart")) db.deleteObjectStore("Cart");
				let table = db.createObjectStore("Cart", { keyPath: "CartId" });
				table.createIndex("ByLineNumber", "LineNumber");
			}
			if (e.newVersion == 2) {
				if (db.objectStoreNames.contains("Cart")) db.deleteObjectStore("Cart");
				let table = db.createObjectStore("Cart", { keyPath: "CartId" });
				table.createIndex("ByLineNumber", "LineNumber");
				table.createIndex("ByProductId", "ProductId");
			}
			// if (e.newVersion == 3) {
			// 	if (db.objectStoreNames.contains("Cart")) db.deleteObjectStore("Cart");
			// 	let table = db.createObjectStore("Cart", { keyPath: "CartId" });
			// 	table.createIndex("ByLineNumber", "LineNumber");
			// 	table.createIndex("ByProductId", "ProductId");
			// 	table.createIndex("ByRetailerId", "StoreId");
			// }
		};
		request.onsuccess = (e) => {
			//console.log("Success getting Db " + dbName);
			response.Success = true;
			response.FailureInformation = "";
			response.Db = e.target.result;
			return resolve(response);
		};

		request.onerror = (e) => {
			//console.log("Error getting Db " + dbName + ": ", e);
			return reject(response);
		};
	});
}

function _getNextLineNumber(lineNumber) {
	return new Promise((resolve, reject) => {
		let response = {
			Success: false,
			FailureInformation: "There was a problem getting the next line number",
		};
		if (lineNumber > 0) return resolve(lineNumber);
		_getDb().then((responseDb) => {
			let db = responseDb.Db;
			let trans = db.transaction(["Cart"], "readwrite");
			trans.oncomplete = (e) => {};
			let store = trans.objectStore("Cart");
			let index = store.index("ByLineNumber");
			let request = index.openCursor(null, "prev");
			request.onsuccess = (e) => {
				if (e.target.result == null) return resolve(1);
				return resolve(e.target.result.key + 1);
			};
			request.onerror = (e) => {
				return reject(response);
			};
		});
	});
}
