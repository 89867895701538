import Vue from "vue";
import Vuex from "vuex";
import commonStore from "./commonStore";
import paymentStore from "./paymentStore";
Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		common: commonStore,
		payment: paymentStore,
	},
});
