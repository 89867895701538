<template>
	<div id="app" :class="getClass">
		<div v-if="$store.getters.showWait" class="wait-background">
			<div class="wait-indicator">
				<!-- <img src="/assets/shopping/images/wait-spinner.gif" width="50" height="50" /> -->
				<b-spinner></b-spinner>
				<br />{{ $t("Please wait") }}...
			</div>
		</div>
		<cToast />
		<cAppTopBar></cAppTopBar>
		<cAppSubBar></cAppSubBar>
		<cAppStoreBar></cAppStoreBar>
		<cAppComboTools></cAppComboTools>
		<cAppLeftDrawer></cAppLeftDrawer>
		<cAppCart></cAppCart>
		<cRetailerSelectModal />

		<div class="app-content-parent scroll" id="main-content">
			<cStorePicker />
			<router-view />
		</div>
		<cPolicyNotice></cPolicyNotice>
		<cAppFooter></cAppFooter>
	</div>
</template>

<script>
import * as _coreService from "@/services/coreService";

import store from "@/store";
import cToast from "@/components/shared/cToast";
import cAppTopBar from "@/components/layout/cAppTopBar";
import cAppComboTools from "@/components/layout/cAppComboTools";
import cAppLeftDrawer from "@/components/layout/cAppLeftDrawer";
import cAppSubBar from "@/components/layout/cAppSubBar";
import cAppStoreBar from "@/components/layout/cAppStoreBar";
import cAppCart from "@/components/layout/cAppCart";
import cAppFooter from "@/components/layout/cAppFooter";
import cPolicyNotice from "@/components/layout/cPolicyNotice";
import cRetailerSelectModal from "@/components/public/cRetailerSelectModal";
import cStorePicker from "@/components/public/product/cStorePicker";

export default {
	name: "app",
	components: { cToast, cAppTopBar, cAppComboTools, cAppLeftDrawer, cAppStoreBar, cAppSubBar, cAppCart, cAppFooter, cPolicyNotice, cRetailerSelectModal, cStorePicker },
	data: () => ({
		pageYOffset: 0,
	}),
	methods: {
		updateYOffset() {
			this.pageYOffset = window.pageYOffset;
		},
	},
	computed: {
		isHeaderVisible: function () {
			return this.pageYOffset < 115;
		},

		getClass() {
			let deviceInfo = this.$store.getters.deviceInfo;
			//console.log(deviceInfo);
			let osName = deviceInfo.Browser.Name;
			let result = deviceInfo.Browser.Name.toLowerCase() + " ";
			result += deviceInfo.OS.Name.toLowerCase() + " ";
			if (!this.isHeaderVisible) result += "no-header" + " ";
			//if (window.self !== window.top) result += "in-frame ";
			if (window.self !== window.top) result += "in-frame ";
			return result;
		},
	},
	watch: {},
	mounted: function () {
		//update the IP address for the rest of the app session
		//just fire and forget...
		_coreService.getClientIp();

		//At some point we can make a service call here to see if the retailer
		//elects to support the multi-retailer feature...
		//this.$store.commit("setMultiRetailer", true);
	},

	created: function () {
		window.addEventListener("scroll", this.updateYOffset, { passive: true });
	},
	destroyed: function () {
		window.removeEventListener("scroll", this.updateYOffset);
	},
};
</script>

<style></style>
