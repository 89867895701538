import vRetailer from "@/views/public/vRetailer.vue";

export default [
  {
    path: "/retailer/:retailerId",
    name: "retailer",
    component: vRetailer,
    meta: { title: null, auth: false, roles: null },
  },
  {
    path: "/index.html",
    name: "spaHome",
    component: vRetailer,
    meta: { title: null, auth: false, roles: null },
  },
  {
    path: "/products",
    name: "products",
    component: () => import(/* webpackChunkName: "product" */ "@/views/public/vProducts.vue"),
    meta: { title: "Products", auth: false, roles: null },
  },
  {
    path: "/productcategories",
    name: "productcategories",
    component: () => import(/* webpackChunkName: "product" */ "@/views/public/vProductCategories.vue"),
    meta: { title: "Products", auth: false, roles: null },
  },
  {
    path: "/productdetail/:retailerId/:code",
    name: "productdetail",
    component: () => import(/* webpackChunkName: "product" */ "@/views/b2b/vProductDetail.vue"),
    meta: { title: "ProductDetail", auth: false, roles: null },
  },
  {
    path: "/filter",
    name: "filter",
    component: () => import(/* webpackChunkName: "product" */ "@/views/public/vFilterWiazrd.vue"),
    meta: { title: "Filter", auth: false, roles: null },
  },
  //Misc bundle
  {
    path: "/retailerselect",
    name: "retailerselect",
    component: () => import(/* webpackChunkName: "misc" */ "@/views/public/vRetailerSelect.vue"),
    meta: { title: "Retailer Selector", auth: false, roles: null },
  },
  {
    path: "/help",
    name: "help",
    component: () => import(/* webpackChunkName: "misc" */ "@/views/public/vHelp.vue"),
    meta: { title: "Help", auth: false, roles: null },
  },
  // {
  // 	path: "/storefinder",
  // 	name: "storefinder",
  // 	component: () => import(/* webpackChunkName: "misc" */ "@/views/public/vStoreFinder.vue"),
  // 	meta: { title: "Store Finder", auth: false, roles: null },
  // },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: () => import(/* webpackChunkName: "misc" */ "@/views/public/vPrivacyPolicy.vue"),
    meta: { title: "Privacy Policy", auth: false, roles: null },
  },
];
