<template>
	<b-sidebar id="combo-tools-drawer" title="" shadow no-header class="open">
		<h4>
			Combine Tools
			<font-awesome-icon icon="times" class="mt-1 mr-1 float-right" @click="closeMenu" />
		</h4>
		<div class="panel-group category-products">
			<div @click="navigateTo('combolist')">{{ $t("My Combinations") }}</div>
			<div @click="navigateTo('combonew')">{{ $t("New Combination") }}</div>

			<!-- <div v-show="isLinkActive('products')" @click="showCombine = !showCombine">{{ $t("Start New Combine") }}</div>
			<div v-show="isLinkActive('products')" @click="clearCombineSelections">{{ $t("Clear Selections") }}</div>
			<div v-show="isLinkActive('products')" @click="createCombination">{{ $t("Create From Selected") }}</div> -->
		</div>
	</b-sidebar>
</template>

<script>
import { eventBus } from "@/main";

export default {
	name: "cAppComboTools",
	components: {},
	props: [],
	data: () => ({
		showCombine: false,
	}),
	methods: {
		load() {},
		clearCombineSelections() {
			eventBus.$emit("clearCombineSelections");
		},
		createCombination() {
			eventBus.$emit("createCombination");
		},
		closeMenu() {
			let el = document.getElementById("combo-tools-drawer");
			if (el) el.classList.remove("open");
		},
		isLinkActive(menuName) {
			let route = this.$route;
			if (route.name == null) return;
			if (menuName === "retailer" && route.name.indexOf("retailer") > -1) {
				return "active";
			} else if (menuName === "products" && route.name.indexOf("products") > -1) {
				return "active";
			} else if (
				menuName === "contractor" &&
				(route.name.indexOf("contractorinvoices") > -1 ||
					route.name.indexOf("contractorstatements") > -1 ||
					route.name.indexOf("contractorsearch") > -1 ||
					route.name.indexOf("contractorpayment") > -1 ||
					route.name.indexOf("contractorusers") > -1 ||
					route.name.indexOf("contractorregulatory") > -1)
			) {
				return "active";
			} else if (
				menuName === "myaccount" &&
				(route.name.indexOf("myaccount") > -1 || route.name.indexOf("profile") > -1 || route.name.indexOf("orderhistory") > -1 || route.name.indexOf("favorites") > -1)
			) {
				return "active";
			} else if (
				menuName === "admin" &&
				(route.name.indexOf("contractorlogo") > -1 ||
					route.name.indexOf("contractorbannerads") > -1 ||
					route.name.indexOf("contractorsplashpage") > -1 ||
					route.name.indexOf("contractorhomepage") > -1)
			) {
				return "active";
			}
			return "";
		},
		navigateTo(routeName) {
			this.showUserMenu = false;
			//don't try to route to the current route
			if (this.$route.name == routeName) return;
			let route = { name: routeName, params: {} };
			if (routeName == "retailer") {
				route.params = JSON.parse('{ "retailerId":"' + this.$store.getters.retailerId + '"}');
			}

			//this.$router.push({ name: routeName });
			this.$router.push(route);
		},
	},
	computed: {},
	watch: {
		showCombine: function (newValue) {
			eventBus.$emit("showCombine", newValue);
		},
	},
	mounted: function () {
		this.load();
	},
};
</script>

<style>
:root {
	--comboToolsWidth: 150px;
	--comboToolsWidthHidden: -175px;
	--comboToolsBGColor: rgba(0, 0, 0, 0.051);
}
.left-drawer-margin {
	padding-right: var(--comboToolsWidth) !important;
}
#combo-tools-drawer {
	display: block !important;
	left: var(--comboToolsWidthHidden);
	right: auto;
	transition: left 1s;
	width: var(--comboToolsWidth);
}
#combo-tools-drawer.open {
	left: 0;
	transition: left 0.5s;
}

#combo-tools-drawer h4 {
	padding: 5px 3px;
	font-size: 16px;
	font-weight: 400;
	color: var(--baseColor);
	border-bottom: 1px solid var(--baseColor);
}
#combo-tools-drawer .panel-group.category-products {
	color: var(--baseColor);
}
#combo-tools-drawer .panel-group.category-products > div {
	padding: 5px 3px;
	border-left: 3px solid transparent;
	cursor: pointer;
}
#combo-tools-drawer .panel-group.category-products > div:hover {
	border-color: var(--accentBackgroundColor);
}
</style>
