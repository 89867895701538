import * as _config from "@/config";
import * as _utility from "@/utility";
import * as _device from "@/utility/device";
import { eventBus } from "@/main";
const myStoreDefault = {
	Ps_id: null,
	Rt_id: 0,
	Number: "",
	RetailerName: "",
	Name: "",
	Hours: "",
	Phone: "",
	Address: "",
	City: "",
	State: "",
	Zip: "",
	Lat: 41.851427, //center of US
	Lng: -99.08997, //center of US
};

const defaultUserInfo = {
	name: "",
	email: null,
	token: null,
	contractorId: null,
	isContractor: false,
	isPrimary: false,
	isRetailerAdmin: false,
	isGlobalAdmin: false,
	isAllowedPayments: false,
	rememberMe: false,
};

function userFromStorage() {
	return new Promise((resolve) => {
		let ls = localStorage.getItem(_config.storageUserKey);
		let ss = sessionStorage.getItem(_config.storageUserKey);
		//if they checked "remember me" then things are stored
		//in local storage
		if (ls != null) {
			//console.log("store:userFromStorage from LocalStorage:");
			//console.log(ls);
			resolve(JSON.parse(ls));
		} else if (ss != null) {
			//console.log("store:userFromStorage from SessionStorage:");
			//console.log(ss);
			resolve(JSON.parse(ss));
		} else resolve(null);
	});
}

const commonStore = {
	state: () => ({
		appId: null,
		user: { ...defaultUserInfo },
		retailerId: 0,
		clientIp: null,
		showWait: false,
		showMenu: false,
		showFilter: true,
		portalAvailable: false,
		systemMessage: {
			color: "info",
			timeout: _config.defaultMessageTimeout,
			text: "",
			position: _config.defaultMessagePosition,
		},
		myStore: { ...myStoreDefault },
		deviceInfo: null,
		multiRetailer: null,
	}),
	mutations: {
		setMultiRetailer(state, value) {
			//console.info("commonStore|saveStore:", value);
			state.multiRetailer = value;
			localStorage.setItem(_config.storageMultiRetailer, value);
		},
		saveStore(state, value) {
			//console.info("commonStore|saveStore:", value);
			state.myStore = value;
			localStorage.setItem(_config.storageSelectedStore, JSON.stringify(value));
			eventBus.$emit("storeSelected", value.Ps_id);
		},
		showWait(state, value) {
			state.showWait = value;
		},
		showMenu(state, value) {
			state.showMenu = value;
		},
		showFilter(state, value) {
			state.showFilter = value;
		},
		dispatchMessage(state, info) {
			if (info == null)
				info = {
					color: "danger",
					timeout: 6000,
					text: "!!Invalid info sent to dispatch Message!!",
					position: _config.defaultMessagePosition,
				};
			if (info.color == null) info.color = "danger";
			if (info.timeout == null) info.timeout = _config.defaultMessageTimeout;
			if (info.position == null) info.position = _config.defaultMessagePosition;
			if (info.text == null) info.text = "!!No message text provided to message dispatch!!";
			state.systemMessage = info;
		},
		portalAvailable(state, info) {
			state.portalAvailable = info;
		},
	},
	actions: {
		isUserLoggedIn({ state }) {
			return new Promise((resolve) => {
				if (state.user.token !== null) {
					resolve(true);
				} else {
					userFromStorage().then((response) => {
						if (response) {
							state.user = response;
							resolve(state.user.token !== null);
						}
						resolve(false);
					});
				}
			});
		},
		logIn({ state }, info) {
			return new Promise((resolve) => {
				if (info == null || info.length == 0) {
					resolve(false);
				}
				let isContractor = false;
				let isPrimary = false;
				let isRetailerAdmin = false;
				let isAllowedPayments = false;
				let isGlboalAdmin = false;
				//console.info(info);
				if (info.info) {
					if (info.info.length >= 0) isContractor = info.info.substr(0, 1) === "1";
					if (info.info.length >= 1) isPrimary = info.info.substr(1, 1) === "1";
					if (info.info.length >= 2) isRetailerAdmin = info.info.substr(2, 1) === "1";
					if (info.info.length >= 3) isAllowedPayments = info.info.substr(3, 1) === "1";
					if (info.info.length >= 4) isGlboalAdmin = info.info.substr(4, 1) === "1";
				}
				state.user = {
					email: info.email,
					token: info.token,
					name: info.name,
					contractorId: info.contractorId,
					portalAvailable: info.portalAvailable,
					isContractor: isContractor,
					isPrimary: isPrimary,
					isRetailerAdmin: isRetailerAdmin,
					isGlobalAdmin: isGlboalAdmin,
					isAllowedPayments: isAllowedPayments,
					rememberMe: info.rememberMe,
				};
				if (state.user.rememberMe) {
					localStorage.setItem(_config.storageUserKey, JSON.stringify(state.user));
				} else {
					sessionStorage.setItem(_config.storageUserKey, JSON.stringify(state.user));
				}
				resolve(true);
			});
		},
		logOut({ state }) {
			return new Promise((resolve) => {
				localStorage.removeItem(_config.storageUserKey);
				sessionStorage.removeItem(_config.storageUserKey);
				state.user = { ...defaultUserInfo };
				resolve(true);
			});
		},
		refreshToken({ state }, token) {
			state.user.token = token;
		},
		refreshClientIp({ state }, clientIp) {
			sessionStorage.setItem(_config.storageIpKey, clientIp);
			state.clientIp = clientIp;
		},
		saveStoreRetailerName(state, value) {
			return new Promise((resolve) => {
				let myStore = state.getters.myStore;
				state.myStore = { ...myStore, RetailerName: value };
				localStorage.setItem(_config.storageSelectedStore, JSON.stringify(state.myStore));
				eventBus.$emit("storeSelected", state.myStore.Ps_id);
				resolve(true);
			});
		},
		saveRetailerId(state, value) {
			return new Promise((resolve) => {
				//Blank out the selected store if it is not for the new retailer
				//console.info("commonStore|action|saveRetailerId", value)
				if (!state.state.myStore || !state.state.myStore.Rt_id || state.state.myStore.Rt_id.toString() !== value.toString()) this.commit("saveStore", myStoreDefault);

				state.state.retailerId = value;
				localStorage.setItem(_config.storageRetailerIdKey, state.state.retailerId);
				resolve(true);
			});
		},
	},
	getters: {
		multiRetailer: (state) => {
			if (state.multiRetailer !== null) {
				return state.multiRetailer;
			} else {
				let ls = localStorage.getItem(_config.storageMultiRetailer);
				if (ls != null) {
					state.multiRetailer = ls === "true";
					return state.multiRetailer;
				} else return false;
			}
		},
		appId: (state) => {
			if (state.appId != null) {
				return state.appId;
			} else {
				let ls = localStorage.getItem(_config.storageAppIdKey);
				if (ls != null) {
					state.appId = ls;
					return ls;
				} else {
					const id = _utility.newGuid();
					localStorage.setItem(_config.storageAppIdKey, id);
					state.appId = id;
					return id;
				}
			}
		},
		email: (state) => (state.user.email === null ? "" : state.user.email),
		token: (state) => (state.user.token === null ? "" : state.user.token),
		//retailerId: (state) => state.retailerId,
		retailerId: (state) => {
			if (state.retailerId > 0) {
				return state.retailerId;
			} else {
				let ls = localStorage.getItem(_config.storageRetailerIdKey);
				if (ls != null) {
					state.retailerId = ls;
					return ls;
				} else return 0;
			}
		},
		contractorId: (state) => state.user.contractorId,
		portalAvailable: (state) => state.portalAvailable,
		isContractor: (state) => (state.user.isContractor === null ? false : state.user.isContractor),
		isPrimary: (state) => (state.user.isPrimary === null ? false : state.user.isPrimary),
		isRetailerAdmin: (state) => (state.user.isRetailerAdmin === null ? false : state.user.isRetailerAdmin),
		isGlobalAdmin: (state) => (state.user.isGlobalAdmin === null ? false : state.user.isGlobalAdmin),
		isAllowedPayments: (state) => (state.user.isAllowedPayments === null ? false : state.user.isAllowedPayments),
		isAuthenticated: (state) => () => {
			if (state.user.token !== null) {
				return true;
			} else {
				//console.log("store:getters:isAuthenticated fired.");
				userFromStorage().then((response) => {
					if (response) {
						state.user = response;
						return true;
					}
					return false;
				});
			}
		},
		clientIp: (state) => {
			if (state.clientIp != null) {
				return state.clientIp;
			} else {
				let ss = sessionStorage.getItem(_config.storageIpKey);
				if (ss != "undefined") {
					state.clientIp = ss;
					return ss;
				} else return "unknown";
			}
		},
		showWait: (state) => state.showWait,
		showMenu: (state) => state.showMenu,
		showFilter: (state) => state.showFilter,
		systemMessage: (state) => state.systemMessage,
		myStore: (state) => {
			if (state.myStore && state.myStore.Ps_id !== null) {
				return state.myStore;
			} else {
				let ls = localStorage.getItem(_config.storageSelectedStore);
				if (ls != "undefined") {
					//console.info("commonStore|getters|myStore:", JSON.parse(ls));
					state.myStore = JSON.parse(ls);
				}
				return state.myStore;
			}
		},
		retailerStoreId: (state) => {
			if (state.myStore && state.myStore.Ps_id !== null) {
				return state.myStore.Ps_id;
			} else {
				let ls = localStorage.getItem(_config.storageSelectedStore);
				if (ls != "undefined") {
					const s = JSON.parse(ls);
					return !s ? "" : s.Ps_id;
				}
				return null;
			}
		},
		storeRetailerName: (state) => {
			if (state.myStore && state.myStore.RetailerName !== null) {
				return state.myStore.RetailerName;
			} else {
				let ls = localStorage.getItem(_config.storageSelectedStore);
				if (ls != "undefined") {
					const s = JSON.parse(ls);
					return !s ? "" : s.RetailerName;
				}
				return "";
			}
		},
		storeName: (state) => {
			if (state.myStore && state.myStore.Name !== null) {
				return state.myStore.Name;
			} else {
				let ls = localStorage.getItem(_config.storageSelectedStore);
				if (ls != "undefined") {
					const s = JSON.parse(ls);
					return !s ? "" : s.Name;
				}
				return "";
			}
		},
		storeAddress: (state) => {
			if (state.myStore && state.myStore.Name !== null) {
				return state.myStore.Address;
			} else {
				let ls = localStorage.getItem(_config.storageSelectedStore);
				if (ls != "undefined") {
					const s = JSON.parse(ls);
					return !s ? "" : s.Address;
				}
				return "";
			}
		},
		deviceInfo: (state) => {
			if (state.deviceInfo !== null) {
				return state.deviceInfo;
			} else {
				let deviceInfo = _device.device.getOsAndBrowser();
				state.deviceInfo = deviceInfo;
				return deviceInfo;
			}
		},
	},
};

export default commonStore;
