<template>
	<b-nav-item-dropdown :text="selectedDisplay" class="locale-select">
		<template v-for="l in locales">
			<b-dropdown-item @click="setLocal(l.Code)" :class="{ 'selected-local': l.Selected }" :key="l.Code">
				{{ l.Display }}
			</b-dropdown-item>
		</template>
	</b-nav-item-dropdown>
</template>

<script>
import * as _utility from "@/utility";
import * as _config from "@/config";

export default {
	name: "cAppLocalizationSelect",
	components: {},
	props: [],

	data: () => ({
		locales: [
			{ Display: "English", Code: "en", Selected: false },
			{ Display: "Español", Code: "es", Selected: false },
		],
	}),
	methods: {
		load: function () {
			let savedLocale = _utility.getFromStorage("locale");
			if (!savedLocale) savedLocale = "en";
			this.$i18n.locale = savedLocale;
			let selectedLocal = this.locales.find((l) => l.Code === this.$i18n.locale);
			if (selectedLocal) selectedLocal.Selected = true;
		},
		setLocal: function (local) {
			this.$i18n.locale = local;
			//console.log("setting local to:", this.$i18n.locale);
			this.locales.forEach((l) => {
				l.Selected = false;
			});
			let selectedLocal = this.locales.find((l) => l.Code === this.$i18n.locale);
			if (selectedLocal) {
				selectedLocal.Selected = true;
				//save the selected locale
				_utility.saveToStorage("locale", this.$i18n.locale);
				//reset some storage properties to trigger reload of DB localized data
				localStorage.removeItem("locale.card");
				localStorage.removeItem(_config.storageSearchOptionsKey);
				window.location.reload();
			}
		},
	},
	computed: {
		selectedLocal: function () {
			let selectedLocal = this.locales.find((l) => l.Selected === true);
			if (selectedLocal) {
				return selectedLocal;
			} else {
				return { Display: "Unknow", Code: "en", Selected: true };
			}
		},
		selectedDisplay: function () {
			return window.screen.width > 576 ? this.selectedLocal.Display : this.selectedLocal.Display.substring(0, 2);
		},
	},
	watch: {},
	created() {},
	mounted: function () {
		this.load();
	},
};
</script>

<style>
.dropdown-item:hover .selected-local a,
.dropdown-item:focus .selected-local a,
.selected-local a {
	color: var(--accentColor2);
}
.nav-link:hover {
	color: white;
}
.dropdown.locale-select {
	position: absolute;
	left: 5px;
}
@media screen and (max-width: 576px) {
}
</style>
