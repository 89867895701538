<template>
	<div class="col-xs-12 col-md-4" v-if="element != null">
		<slot></slot>
		<router-link :to="navigateTo">
			<div class="card hover">
				<img :src="imageUrl" width="100%" />
				<div class="description">
					<h2>{{ $t(element.Title) }}</h2>
					<!-- <router-link :to="navigateTo" class="btn btn-card" :target="getTarget">{{ $t(element.ButtonText) }}</router-link> -->
				</div>
			</div>
		</router-link>
	</div>
</template>

<script>
export default {
	name: "cHomeCardElement",
	components: {},
	props: ["element", "editor"],
	data: () => ({}),
	computed: {
		imageUrl: function () {
			return this.element.ImageBase64String ? this.element.ImageBase64String : "/assets/images/no-image-600x450.png";
		},
		getTarget: function () {
			return this.editor ? "_blank" : "_self";
		},
		navigateTo: function () {
			if (this.element.URL == null || this.element.URL.length == 0) return "/";
			return this.element.URL;
		},
	},
	mounted: function () {},
};
</script>

<style>
.card.hover {
	cursor: pointer;
}
</style>
