<template>
	<div class="app-top-bar">
		<b-nav small align="right">
			<cAppLocalizationSelect />

			<b-navbar-nav class="store-link hide-small">
				<b-nav-item-dropdown>
					<template v-slot:button-content>
						<span v-if="$store.getters.storeName.length > 0">
							<font-awesome-icon icon="location-dot" />
							<span class="hide-small ml-2">{{ $t("YoureShopping") }}</span>
							<span class="ml-1">
								{{ storeDisplay }}
							</span>
						</span>

						<button class="btn btn-primary" @click="openStorePicker">
							<span class="hide-small">{{ $t("FindStore") }}</span>
							<span class="show-small"><font-awesome-icon icon="search" class="text-white" /></span>
						</button>
					</template>
					<b-dropdown-item v-if="$store.getters.storeName.length > 0" class="selected-local">
						<div>{{ $store.getters.myStore.Name }}</div>
						<div>{{ $store.getters.myStore.Address }}</div>
						<div>{{ $store.getters.myStore.City }}, {{ $store.getters.myStore.State }} {{ $store.getters.myStore.Zip }}</div>
						<div>{{ $store.getters.myStore.Phone }}</div>
						<div v-html="$store.getters.myStore.Hours"></div>
					</b-dropdown-item>
					<b-dropdown-item @click="openStorePicker">
						{{ $t("FindStore") }}
					</b-dropdown-item>
				</b-nav-item-dropdown>
			</b-navbar-nav>

			<b-navbar-nav>
				<b-nav-item-dropdown>
					<template v-slot:button-content>
						<font-awesome-icon class="mr-2" :icon="$store.getters.isAuthenticated() ? 'user' : 'user-lock'" />
					</template>
					<b-dropdown-item v-if="$store.getters.isAuthenticated()" class="selected-local">{{ $store.getters.email }}</b-dropdown-item>
					<b-dropdown-item v-if="$store.getters.isAuthenticated()" @click="navigateTo('profile')">{{ $t("Profile") }}</b-dropdown-item>
					<b-dropdown-item v-if="$store.getters.isAuthenticated()" @click="navigateTo('logout')">{{ $t("Logout") }}</b-dropdown-item>
					<b-dropdown-item v-if="!$store.getters.isAuthenticated()" @click="navigateTo('login')">{{ $t("Login") }}</b-dropdown-item>
				</b-nav-item-dropdown>
			</b-navbar-nav>
			<cAppCartIcon class="top-cart-icon" />
		</b-nav>
	</div>
</template>

<script>
import cAppCartIcon from "@/components/layout/cAppCartIcon";
import cAppLocalizationSelect from "@/components/layout/cAppLocalizationSelect";
import cAppfilter from "@/components/filters/cAppFilter";

import { eventBus } from "@/main";
import * as _publicService from "@/services/publicService";
export default {
	name: "cAppTopBar",
	components: { cAppCartIcon, cAppLocalizationSelect, cAppfilter },
	props: [],
	data: () => ({
		retailerId: 0,
		retailerName: "",
		selectedCombine: [],
		showCombine: false,
	}),
	methods: {
		load() {},
		clearCombineSelections() {
			eventBus.$emit("clearCombineSelections");
		},
		createCombination() {
			eventBus.$emit("createCombination");
		},
		navigateTo(routeName) {
			//don't try to route to the current route
			if (this.$route.name == routeName) return;
			this.$router.push({ name: routeName });
		},
		openStorePicker() {
			this.$bvModal.show("modal-store-picker");
			setTimeout(() => {
				eventBus.$emit("storePickerOpened");
				let el = document.getElementById("postal-input");
				if (el) el.focus();
			}, 500);
		},
	},
	computed: {
		showCombineButtons: function () {
			return this.$route.name == "products";
		},
		showCartIcon: function () {
			return this.$route.name != "checkout";
		},
		storeDisplay: function () {
			let display = this.$store.getters.storeName + " " + this.$store.getters.myStore.Address;
			return window.screen.width > 576 ? display : display.substring(0, 25) + "..";
		},
	},
	watch: {
		"$store.state.common.myStore": {
			handler: function (newStore) {
				let s = this.$store.getters.myStore;
				//console.info("cAppSubBar|watch|myStore:", s.RetailerName, s.Name);
				if (s) {
					let result = s.RetailerName;
					//if (s.Name.length > 0) result += ", " + s.Name;
					result += ", " + s.Name;
					this.retailerName = result;
				}
			},
			deep: true,
			immediate: true,
		},
		"$store.state.common.retailerId": {
			handler: function (newvalue) {
				this.retailerId = newvalue;
				//console.info("cAppSubBar|watch|user:", newvalue);
				//if (retailerId != 0) this.retailerId = newStore.retailerId;
			},
			deep: true,
			immediate: true,
		},
		showCombine: function (newValue) {
			eventBus.$emit("showCombine", newValue);
		},
	},
	mounted: function () {},
};
</script>

<style>
.app-top-bar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: var(--topBarHeight);
	color: white !important;
	background: none repeat scroll 0 0 var(--accentBackgroundColor);
	z-index: 2;
}
.app-top-bar .svg-inline--fa,
.app-top-bar a:focus {
	color: white;
}
.store-link .show-small {
	display: none;
}
.app-top-bar .btn {
	font-size: 0.9em;
	padding: 2px 5px;
	min-width: auto;
}
.app-top-bar .btn.offset {
	position: relative;
	top: 8px;
}
@media screen and (max-width: 769px) {
	.app-top-bar {
	}
}

@media screen and (max-width: 576px) {
	.hide-small,
	.store-link .hide-small,
	.navbar-nav .hide-small {
		display: none !important;
	}
	.show-small,
	.store-link .show-small {
		display: inline-block;
	}
}
</style>
