import store from "@/store";
import * as _core from "./coreService";
import * as _config from "@/config";
import * as _const from "@/utility/constants";
import * as _utility from "@/utility";
import * as _cartIndexDb from "./cartIndexDbService";

export function addToCart(product, callback) {
  //console.log("cartService|addToCard|product:");
  //console.log(product);
  let data = { UPC: product.UPC, Quantity: product.Quantity };
  _getUnitPrice(data, function (priceResponse) {
    if (!priceResponse.Success) {
      store.commit("dispatchMessage", {
        color: "danger",
        text: priceResponse.FailureInformation,
      });
      return callback(priceResponse);
    }
    product.UnitPrice = priceResponse.UnitPrice > 0 ? priceResponse.UnitPrice : product.RetailPrice;
    let retailerStoreId = store.getters.retailerStoreId;
    _cartIndexDb.addToCart(product, retailerStoreId).then((response) => {
      if (!response.Success) {
        store.commit("dispatchMessage", {
          color: "danger",
          text: response.FailureInformation,
        });
      }
      return callback(response);
    });
  });
}

export function emptyCart(callback) {
  let retailerStoreId = store.getters.retailerStoreId;
  _cartIndexDb.emptyCart(retailerStoreId).then((response) => {
    if (!response.Success) {
      store.commit("dispatchMessage", {
        color: "danger",
        text: response.FailureInformation,
      });
    }
    return callback(response);
  });
}

export function isProductInCart(productId, callback) {
  let retailerStoreId = store.getters.retailerStoreId;
  _cartIndexDb.isProductInCart(productId, retailerStoreId).then((response) => {
    if (!response.Success) {
      store.commit("dispatchMessage", {
        color: "danger",
        text: response.FailureInformation,
      });
    }
    return callback(response);
  });
}

export function removeFromCart(cartId, callback) {
  _cartIndexDb.removeFromCart(cartId).then((response) => {
    if (!response.Success) {
      store.commit("dispatchMessage", {
        color: "danger",
        text: response.FailureInformation,
      });
    }
    return callback(response);
  });
}

export function getCart(callback) {
  let retailerStoreId = store.getters.retailerStoreId;
  _cartIndexDb.getCart(retailerStoreId, false).then((response) => {
    if (!response.Success) {
      store.commit("dispatchMessage", {
        color: "danger",
        text: response.FailureInformation,
      });
    }
    return callback(response);
  });
}

export function getPaymentOptions(callback) {
  let url = _config.urlCartService + "GetPaymentOptions/";
  let data = {};
  _core.serviceCallGet(url, data, true, (response) => {
    if (callback) callback(response);
  });
}

export function getPaintUPC(data, callback) {
  let url = _config.urlCartService + "GetPaintUPC/";
  _core.serviceCallGet(url, data, true, (response) => {
    if (callback) callback(response);
  });
}

export function getPaintUPCQuantity(data, callback) {
  let url = _config.urlCartService + "GetPaintUPCQuantity/";
  _core.serviceCallGet(url, data, true, (response) => {
    if (callback) callback(response);
  });
}

export function getStorePickupDeliveryOptions(callback) {
  let url = _config.urlCartService + "GetStorePickupDeliveryOptions/";
  /* 	let myStore = store.getters.myStore;
	if (myStore.Ps_id === null) {
		if (callback) callback({ Success: false, FailureInformation: "No store selected!" });
		return;
	} */
  let data = {};
  _core.serviceCallGet(url, data, true, (response) => {
    if (callback) callback(response);
  });
}

export function getUnitPrices(data, callback) {
  let url = _config.urlCartService + "GetUnitPrices/";
  _core.serviceCallPost(url, data, true, (response) => {
    if (callback) callback(response);
  });
}

export function updateItemLocation(cartId, newValue, callback) {
  _cartIndexDb.updateItemLocation(cartId, newValue).then((response) => {
    if (!response.Success) {
      store.commit("dispatchMessage", {
        color: "danger",
        text: response.FailureInformation,
      });
    }
    return callback(response);
  });
}

function _getUnitPrice(data, callback) {
  let url = _config.urlCartService + "GetUnitPrice/";
  _core.serviceCallGet(url, data, true, (response) => {
    if (callback) callback(response);
  });
}
