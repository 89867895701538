<template>
	<div class="app-content">
		<cLogIn />
	</div>
</template>

<script>
// @ is an alias to /src
import cLogIn from "@/components/user/cLogIn.vue";

export default {
	name: "vLogIn",
	components: {
		cLogIn,
	},
};
</script>
<style scoped></style>
