<template>
	<div>
		<section id="form" autocomplete="off">
			<!--form-->
			<div class="container">
				<div class="row">
					<div class="col-sm-2">
						<!-- <router-link :to="'retailer/' + $store.getters.retailerId" class="accentColor2"><font-awesome-icon icon="arrow-circle-left" /> Back To Home</router-link> -->
					</div>
					<div class="col-sm-8">
						<div class="alert alert-danger" v-if="message">{{ message }}</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-1"></div>
					<div class="col-sm-4">
						<form class="input-form" onsubmit="event.preventDefault();">
							<!--login form-->
							<h2>{{ $t("Login To Your Account") }}</h2>
							<cValidatedInput class="full-width" v-model="$v.form.Email" label="" :placeholder="$t('Email')"></cValidatedInput>
							<cValidatedInput class="full-width" v-model="$v.form.Password" type="password" label="" :placeholder="$t('Password')"></cValidatedInput>
							<!-- <div>
								<input type="checkbox" class="checkbox" style="width: 25px; display: inline-block; margin-right: 10px;" id="keep" name="keep" v-model="form.RememberMe" />
								<label for="keep" style="top: -15px; position: relative;">{{ $t("Keep me signed in") }}</label>
							</div> -->
							<router-link class="pull-right" style="top: 5px; position: relative" to="/forgotpassword">{{ $t("Forgot Password") }}</router-link>
							<button type="submit" class="btn btn-default" @click="logIn" :disabled="!formIsValid($v.form)">
								{{ $t("Login") }}
							</button>
						</form>
						<!--/login form-->
					</div>
					<div class="col-sm-1 text-center" v-show="allowSignUp">
						<h2 class="or">{{ $t("or") }}</h2>
					</div>
					<div class="col-sm-4" v-show="allowSignUp">
						<form class="input-form" onsubmit="event.preventDefault();" allowAutoComplete="off">
							<!--sign up form-->
							<h2>{{ $t("New User Signup") }}</h2>
							<cValidatedInputNoAutocomplete class="full-width" v-model="$v.formNewAccount.Email" label="" :placeholder="$t('Email')" elementId="signup-email"></cValidatedInputNoAutocomplete>
							<cValidatedInputNoAutocomplete
								class="full-width"
								v-model="$v.formNewAccount.Password"
								type="password"
								label=""
								:placeholder="$t('Password')"
								elementId="signup-password"
							></cValidatedInputNoAutocomplete>
							<button type="submit" class="btn btn-default" @click="newAccount" :disabled="!formIsValid($v.formNewAccount)">
								{{ $t("Signup") }}
							</button>
						</form>
						<!--/sign up form-->
					</div>
				</div>
				<div class="row mt-3">
					<div class="col-sm-1"></div>

					<div class="col-sm-1 text-center">
						<!-- <router-link :to="getLink()" class="btn btn-link">Cancel</router-link> -->
						<a class="btn btn-link" :href="getLink()">{{ $t("Cancel") }}</a>
					</div>
				</div>
			</div>
		</section>
		<!--/form-->
	</div>
</template>

<script>
import cValidatedInput from "@/components/shared/cValidatedInput";
import cValidatedInputNoAutocomplete from "@/components/shared/cValidatedInputNoAutocomplete";

import * as _userService from "@/services/userService";
//https://vuelidate.js.org/#sub-builtin-validators
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import { password } from "@/utility/validatorsCustom";
import * as _validation from "@/utility/validation";
import * as _config from "@/config";
import { eventBus } from "@/main";

export default {
	name: "cLogIn",
	components: { cValidatedInput, cValidatedInputNoAutocomplete },
	props: ["value"],
	data: () => ({
		form: {
			Email: "",
			Password: "",
			RememberMe: false,
		},
		formNewAccount: {
			Email: "",
			Password: "",
		},
		allowSignUp: true,
		message: null,
	}),
	methods: {
		load() {
			if (this.$route.params != null && this.$route.params.retailerchanged != null) {
				if (this.$route.params.retailerchanged == 1) this.message = this.$t("LoginRetailerChanged") + this.$store.getters.retailerId;
			}
			_userService.isCreateAccountAllowed((response) => {
				if (response.Success) {
					this.allowSignUp = response.IsCreateAccountAllowed;
				}
			});
		},
		getLink() {
			// let route = { name: "retailer", params: {} };
			// route.params = JSON.parse('{ "retailerId":"' + this.$store.getters.retailerId + '"}');
			// return route;
			return "/retailer/" + this.$store.getters.retailerId;
		},
		logIn() {
			this.$v.form.$touch();
			if (this.$v.form.$anyError) {
				return;
			}
			this.message = null;
			_userService.logIn(this.form, (response) => {
				if (!response.Success) {
					this.$store.commit("dispatchMessage", {
						color: "danger",
						text: response.FailureInformation,
						y: "top", //optional, default in config, top/bottom/null
						timeout: 6000, //optional, default in config
					});
					this.message = response.Message;
				} else {
					//See if this was a new user sign up during checkout...
					let ls = localStorage.getItem(_config.storageSignupAddressKey);
					if (ls) {
						const userInfo = JSON.parse(ls);
						if (userInfo.Email != this.form.Email) localStorage.removeItem(_config.storageSignupAddressKey);
					}
					setTimeout(() => {
						eventBus.$emit("login");
					}, 2000);
					eventBus.$emit("storeSelected", response.Store.Ps_id);
					this.$router.push("/retailer/" + response.RetailerId);
				}
			});
		},
		newAccount() {
			this.$v.formNewAccount.$touch();
			if (this.$v.formNewAccount.$anyError) {
				return;
			}
			sessionStorage.setItem("fusion.tmp", JSON.stringify(this.formNewAccount));
			this.$router.push("signup");
		},
		formIsValid(f) {
			return _validation.formIsValid(f);
		},
		fieldIsValid(p) {
			return _validation.validationState(p);
		},
		validationErrorMessage(p) {
			return _validation.validationErrorMessage(p);
		},
	},
	validations: {
		form: {
			Email: { required, email },
			Password: { required },
		},
		formNewAccount: {
			Email: {
				required,
				email,
				emailAvailable(value) {
					if (!value || value === "") return true;
					if (this.form.Email === value) return true;
					return new Promise((resolve, reject) => {
						_userService.isEmailUsed(value, (response) => {
							if (response.Success) {
								resolve(!response.IsUsed);
							} else resolve(true);
						});
					});
				},
			},
			Password: { password },
		},
	},
	computed: {
		showNewUserForm: function () {
			return this.formNewAccount.Email.length > 0;
		},
	},
	watch: {},
	mounted: function () {
		this.load();
	},
};
</script>

<style></style>
